export const namespaced = true 
import axios from 'axios'
import router from '@/router'
//import store from '@/store'

export default {
    state : {
        banners :"",
        banner : '', 
        testimonies :"",
        testimony : '', 
        galleries :"",
        gallery : '', 
        client :"",
        clients : '',
        team :"",
        teams : '',
        websetting :''

    },
    
    getters : {
        banners: (state )=> state.banners,
		banner: (state )=> state.banner,
        testimonies: (state )=> state.testimonies,
		testimony: (state )=> state.testimony,
        galleries: (state )=> state.galleries,
		gallery: (state )=> state.gallery,
        clients: (state )=> state.clients,
		client: (state )=> state.client,
        teams: (state )=> state.teams,
		team: (state )=> state.team,
        websetting: (state )=> state.websetting,
    },


actions : {



    //bannners

    async savebanner( { commit }, data) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', 'savebanner')
        return new Promise((resolve, reject) => {
		axios.post("admin/banners/create",  data,
        {
            onUploadProgress: function( progressEvent ) {
              var newuploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
              commit('setuploadPercentage', newuploadPercentage )
            }.bind(this)
           
          },)
		.then(response => {	
			commit('setloader', false)
            commit('setuploadPercentage', 0 )
			if(response.data.res){
				commit('setbanners',response.data.res)
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('seterror', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });
		
			
	},

    async updatebanner( { commit }, {data, id}) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', id)
        return new Promise((resolve, reject) => {
		axios.post("admin/banners/update/"+ id ,  data)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				commit('setbanners',response.data.res)
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('seterror', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });
				
	},

    async updatebannerimage( { commit }, {data, id}) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', id)
        return new Promise((resolve, reject) => {
		axios.post("admin/banners/updateimage/"+ id ,  data)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				commit('setbanner',response.data.res)
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('seterror', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });
				
	},

	async getallbanners( { commit }, page) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', 'savebanner')
        return new Promise((resolve, reject) => {
		axios.get("admin/banners", page)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				commit('setbanners',response.data.res)
				
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('seterror', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });
		
			
	},

	async getbanner( { commit }, id) {
			commit('seterror', '')
			commit('setmsg', '')
			commit('setloader', id)
			return new Promise((resolve, reject) => {
			axios.get("admin/banners/" + id)
			.then(response => {	
				commit('setloader', false)
				if(response.data.res){
					commit('setbanner',response.data.res)	
				}
				resolve()
			})
			.catch(error=> {
				commit('setloader', false)
				if (error.response) {
					if(error.response.data){
						if(error.response.data.msg){
							commit('seterror', error.response.data.msg)
						}else if(error.response.data.message){
							commit('seterror', error.response.data.message)
						}
						
					}
				}
				reject()
			});
		});
			
				
	},


	async changebannerstatus( { commit }, id) {
			commit('seterror', '')
			commit('setmsg', '')
			commit('setloader', id)
			return new Promise((resolve, reject) => {
			axios.get("admin/banners/status/" + id)
			.then(response => {	
				commit('setloader', false)
				if(response.data.res){
					commit('setbanners',response.data.res)
					
				}
				resolve()
			})
			.catch(error=> {
				commit('setloader', false)
				if (error.response) {
					if(error.response.data){
						if(error.response.data.msg){
							commit('seterror', error.response.data.msg)
						}else if(error.response.data.message){
							commit('seterror', error.response.data.message)
						}
						
					}
				}
				reject()
			});
		});
				
	},

	async deletebanner( { commit }, id) {
			commit('seterror', '')
			commit('setmsg', '')
			commit('setloader', id)
			return new Promise((resolve, reject) => {
			axios.get("admin/banners/delete/" + id)
			.then(response => {	
				commit('setloader', false)
				if(response.data.res){
					commit('setbanners',response.data.res)
					
				}
				resolve()
			})
			.catch(error=> {
				commit('setloader', false)
				if (error.response) {
					if(error.response.data){
						if(error.response.data.msg){
							commit('seterror', error.response.data.msg)
						}else if(error.response.data.message){
							commit('seterror', error.response.data.message)
						}
						
					}
				}
				reject()
			});
		});
				
	},





        //testimony

        async savetestimony( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'savetestimony')
            return new Promise((resolve, reject) => {
            axios.post("admin/testimonies/create",  data,
            {
                onUploadProgress: function( progressEvent ) {
                  var newuploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
                  commit('setuploadPercentage', newuploadPercentage )
                }.bind(this)
               
              },)
            .then(response => {	
                commit('setloader', false)
                commit('setuploadPercentage', 0 )
                if(response.data.res){
                    commit('settestimonies',response.data.res)
                }
                resolve()
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject()
            });
        });
            
                
        },
    
        async updatetestimony( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', id)
            return new Promise((resolve, reject) => {
            axios.post("admin/testimonies/update/"+ id ,  data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('settestimonies',response.data.res)
                }
                resolve()
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject()
            });
        });
                    
        },
    
        async updatetestimonyimage( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', id)
            return new Promise((resolve, reject) => {
            axios.post("admin/testimonies/updateimage/"+ id ,  data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('settestimony',response.data.res)
                }
                resolve()
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject()
            });
        });
                    
        },
    
        async getalltestimonies( { commit }, page) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'savetestimony')
            return new Promise((resolve, reject) => {
            axios.get("admin/testimonies", page)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('settestimonies',response.data.res)
                    
                }
                resolve()
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject()
            });
        });
            
                
        },
    
        async gettestimony( { commit }, id) {
                commit('seterror', '')
                commit('setmsg', '')
                commit('setloader', id)
                return new Promise((resolve, reject) => {
                axios.get("admin/testimonies/" + id)
                .then(response => {	
                    commit('setloader', false)
                    if(response.data.res){
                        commit('settestimony',response.data.res)	
                    }
                    resolve()
                })
                .catch(error=> {
                    commit('setloader', false)
                    if (error.response) {
                        if(error.response.data){
                            if(error.response.data.msg){
                                commit('seterror', error.response.data.msg)
                            }else if(error.response.data.message){
                                commit('seterror', error.response.data.message)
                            }
                            
                        }
                    }
                    reject()
                });
            });
                
                    
        },
    
    
        async changetestimoniestatus( { commit }, id) {
                commit('seterror', '')
                commit('setmsg', '')
                commit('setloader', id)
                return new Promise((resolve, reject) => {
                axios.get("admin/testimonies/status/" + id)
                .then(response => {	
                    commit('setloader', false)
                    if(response.data.res){
                        commit('settestimonies',response.data.res)
                        
                    }
                    resolve()
                })
                .catch(error=> {
                    commit('setloader', false)
                    if (error.response) {
                        if(error.response.data){
                            if(error.response.data.msg){
                                commit('seterror', error.response.data.msg)
                            }else if(error.response.data.message){
                                commit('seterror', error.response.data.message)
                            }
                            
                        }
                    }
                    reject()
                });
            });
                    
        },
    
        async deletetestimony( { commit }, id) {
                commit('seterror', '')
                commit('setmsg', '')
                commit('setloader', id)
                return new Promise((resolve, reject) => {
                axios.get("admin/testimonies/delete/" + id)
                .then(response => {	
                    commit('setloader', false)
                    if(response.data.res){
                        commit('settestimonies',response.data.res)
                        
                    }
                    resolve()
                })
                .catch(error=> {
                    commit('setloader', false)
                    if (error.response) {
                        if(error.response.data){
                            if(error.response.data.msg){
                                commit('seterror', error.response.data.msg)
                            }else if(error.response.data.message){
                                commit('seterror', error.response.data.message)
                            }
                            
                        }
                    }
                    reject()
                });
            });
                    
        },






           //Gallery

        async savegallery( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'savegallery')
            return new Promise((resolve, reject) => {
            axios.post("admin/galleries/create",  data,
            {
                onUploadProgress: function( progressEvent ) {
                  var newuploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
                  commit('setuploadPercentage', newuploadPercentage )
                }.bind(this)
               
              },)
            .then(response => {	
                commit('setloader', false)
                commit('setuploadPercentage', 0 )
                if(response.data.res){
                    commit('setgalleries',response.data.res)
                }
                resolve()
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject()
            });
        });
            
                
        },
    
        async updategallery( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', id)
            return new Promise((resolve, reject) => {
            axios.post("admin/galleries/update/"+ id ,  data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setgalleries',response.data.res)
                }
                resolve()
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject()
            });
        });
                    
        },
    
        async updategalleryimage( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', id)
            return new Promise((resolve, reject) => {
            axios.post("admin/galleries/updateimage/"+ id ,  data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setgallery',response.data.res)
                }
                resolve()
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject()
            });
        });
                    
        },
    
        async getallgalleries( { commit }, page) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'savegallery')
            return new Promise((resolve, reject) => {
            axios.get("admin/galleries", page)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setgalleries',response.data.res)
                    
                }
                resolve()
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject()
            });
        });
            
                
        },
    
        async getgallery( { commit }, id) {
                commit('seterror', '')
                commit('setmsg', '')
                commit('setloader', id)
                return new Promise((resolve, reject) => {
                axios.get("admin/galleries/" + id)
                .then(response => {	
                    commit('setloader', false)
                    if(response.data.res){
                        commit('setgallery',response.data.res)	
                    }
                    resolve()
                })
                .catch(error=> {
                    commit('setloader', false)
                    if (error.response) {
                        if(error.response.data){
                            if(error.response.data.msg){
                                commit('seterror', error.response.data.msg)
                            }else if(error.response.data.message){
                                commit('seterror', error.response.data.message)
                            }
                            
                        }
                    }
                    reject()
                });
            });
                
                    
        },
    
    
        async changegalleriestatus( { commit }, id) {
                commit('seterror', '')
                commit('setmsg', '')
                commit('setloader', id)
                return new Promise((resolve, reject) => {
                axios.get("admin/galleries/status/" + id)
                .then(response => {	
                    commit('setloader', false)
                    if(response.data.res){
                        commit('setgalleries',response.data.res)
                        
                    }
                    resolve()
                })
                .catch(error=> {
                    commit('setloader', false)
                    if (error.response) {
                        if(error.response.data){
                            if(error.response.data.msg){
                                commit('seterror', error.response.data.msg)
                            }else if(error.response.data.message){
                                commit('seterror', error.response.data.message)
                            }
                            
                        }
                    }
                    reject()
                });
            });
                    
        },
    
        async deletegallery( { commit }, id) {
                commit('seterror', '')
                commit('setmsg', '')
                commit('setloader', id)
                return new Promise((resolve, reject) => {
                axios.get("admin/galleries/delete/" + id)
                .then(response => {	
                    commit('setloader', false)
                    if(response.data.res){
                        commit('setgalleries',response.data.res)
                        
                    }
                    resolve()
                })
                .catch(error=> {
                    commit('setloader', false)
                    if (error.response) {
                        if(error.response.data){
                            if(error.response.data.msg){
                                commit('seterror', error.response.data.msg)
                            }else if(error.response.data.message){
                                commit('seterror', error.response.data.message)
                            }
                            
                        }
                    }
                    reject()
                });
            });
                    
        },












        

           //clients

        async saveclient( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'saveclient')
            return new Promise((resolve, reject) => {
            axios.post("admin/clients/create",  data,
            {
                onUploadProgress: function( progressEvent ) {
                  var newuploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
                  commit('setuploadPercentage', newuploadPercentage )
                }.bind(this)
               
              },)
            .then(response => {	
                commit('setloader', false)
                commit('setuploadPercentage', 0 )
                if(response.data.res){
                    commit('setclients',response.data.res)
                }
                resolve()
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject()
            });
        });
            
                
        },
    
        async updateclient( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', id)
            return new Promise((resolve, reject) => {
            axios.post("admin/clients/update/"+ id ,  data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setclients',response.data.res)
                }
                resolve()
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject()
            });
        });
                    
        },
    
        async updateclientimage( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', id)
            return new Promise((resolve, reject) => {
            axios.post("admin/clients/updateimage/"+ id ,  data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setclient',response.data.res)
                }
                resolve()
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject()
            });
        });
                    
        },
    
        async getallclients( { commit }, page) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'saveclient')
            return new Promise((resolve, reject) => {
            axios.get("admin/clients", page)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setclients',response.data.res)
                    
                }
                resolve()
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject()
            });
        });
            
                
        },
    
        async getclient( { commit }, id) {
                commit('seterror', '')
                commit('setmsg', '')
                commit('setloader', id)
                return new Promise((resolve, reject) => {
                axios.get("admin/clients/" + id)
                .then(response => {	
                    commit('setloader', false)
                    if(response.data.res){
                        commit('setclient',response.data.res)	
                    }
                    resolve()
                })
                .catch(error=> {
                    commit('setloader', false)
                    if (error.response) {
                        if(error.response.data){
                            if(error.response.data.msg){
                                commit('seterror', error.response.data.msg)
                            }else if(error.response.data.message){
                                commit('seterror', error.response.data.message)
                            }
                            
                        }
                    }
                    reject()
                });
            });
                
                    
        },
    
    
        async changeclientstatus( { commit }, id) {
                commit('seterror', '')
                commit('setmsg', '')
                commit('setloader', id)
                return new Promise((resolve, reject) => {
                axios.get("admin/clients/status/" + id)
                .then(response => {	
                    commit('setloader', false)
                    if(response.data.res){
                        commit('setclients',response.data.res)
                        
                    }
                    resolve()
                })
                .catch(error=> {
                    commit('setloader', false)
                    if (error.response) {
                        if(error.response.data){
                            if(error.response.data.msg){
                                commit('seterror', error.response.data.msg)
                            }else if(error.response.data.message){
                                commit('seterror', error.response.data.message)
                            }
                            
                        }
                    }
                    reject()
                });
            });
                    
        },
    
        async deleteclient( { commit }, id) {
                commit('seterror', '')
                commit('setmsg', '')
                commit('setloader', id)
                return new Promise((resolve, reject) => {
                axios.get("admin/clients/delete/" + id)
                .then(response => {	
                    commit('setloader', false)
                    if(response.data.res){
                        commit('setclients',response.data.res)
                        
                    }
                    resolve()
                })
                .catch(error=> {
                    commit('setloader', false)
                    if (error.response) {
                        if(error.response.data){
                            if(error.response.data.msg){
                                commit('seterror', error.response.data.msg)
                            }else if(error.response.data.message){
                                commit('seterror', error.response.data.message)
                            }
                            
                        }
                    }
                    reject()
                });
            });
                    
        },




    
           //teams

        async saveteam( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'saveteam')
            return new Promise((resolve, reject) => {
            axios.post("admin/teams/create",  data,
            {
                onUploadProgress: function( progressEvent ) {
                  var newuploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
                  commit('setuploadPercentage', newuploadPercentage )
                }.bind(this)
               
              },)
            .then(response => {	
                commit('setloader', false)
                commit('setuploadPercentage', 0 )
                if(response.data.res){
                    commit('setteams',response.data.res)
                }
                resolve()
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject()
            });
        });
            
                
        },
    
        async updateteam( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', id)
            return new Promise((resolve, reject) => {
            axios.post("admin/teams/update/"+ id ,  data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setteams',response.data.res)
                }
                resolve()
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject()
            });
        });
                    
        },
    
        async updateteamimage( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', id)
            return new Promise((resolve, reject) => {
            axios.post("admin/teams/updateimage/"+ id ,  data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setteam',response.data.res)
                }
                resolve()
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject()
            });
        });
                    
        },
    
        async getallteams( { commit }, page) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'saveteam')
            return new Promise((resolve, reject) => {
            axios.get("admin/teams", page)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setteams',response.data.res)
                    
                }
                resolve()
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject()
            });
        });
            
                
        },
    
        async getteam( { commit }, id) {
                commit('seterror', '')
                commit('setmsg', '')
                commit('setloader', id)
                return new Promise((resolve, reject) => {
                axios.get("admin/teams/" + id)
                .then(response => {	
                    commit('setloader', false)
                    if(response.data.res){
                        commit('setteam',response.data.res)	
                    }
                    resolve()
                })
                .catch(error=> {
                    commit('setloader', false)
                    if (error.response) {
                        if(error.response.data){
                            if(error.response.data.msg){
                                commit('seterror', error.response.data.msg)
                            }else if(error.response.data.message){
                                commit('seterror', error.response.data.message)
                            }
                            
                        }
                    }
                    reject()
                });
            });
                
                    
        },
    
    
        async changeteamstatus( { commit }, id) {
                commit('seterror', '')
                commit('setmsg', '')
                commit('setloader', id)
                return new Promise((resolve, reject) => {
                axios.get("admin/teams/status/" + id)
                .then(response => {	
                    commit('setloader', false)
                    if(response.data.res){
                        commit('setteams',response.data.res)
                        
                    }
                    resolve()
                })
                .catch(error=> {
                    commit('setloader', false)
                    if (error.response) {
                        if(error.response.data){
                            if(error.response.data.msg){
                                commit('seterror', error.response.data.msg)
                            }else if(error.response.data.message){
                                commit('seterror', error.response.data.message)
                            }
                            
                        }
                    }
                    reject()
                });
            });
                    
        },
    
        async deleteteam( { commit }, id) {
                commit('seterror', '')
                commit('setmsg', '')
                commit('setloader', id)
                return new Promise((resolve, reject) => {
                axios.get("admin/teams/delete/" + id)
                .then(response => {	
                    commit('setloader', false)
                    if(response.data.res){
                        commit('setteams',response.data.res)
                        
                    }
                    resolve()
                })
                .catch(error=> {
                    commit('setloader', false)
                    if (error.response) {
                        if(error.response.data){
                            if(error.response.data.msg){
                                commit('seterror', error.response.data.msg)
                            }else if(error.response.data.message){
                                commit('seterror', error.response.data.message)
                            }
                            
                        }
                    }
                    reject()
                });
            });
                    
        },



        //websettings
        async getwebsettings( { commit }) {
                commit('seterror', '')
                commit('setmsg', '')
                commit('setloader', 'getwebsettings')
                return new Promise((resolve, reject) => {
                axios.get("admin/settings")
                .then(response => {	
                    commit('setloader', false)
                    if(response.data.res){
                        commit('setwebsetting',response.data.res)
                        
                    }
                    resolve()
                })
                .catch(error=> {
                    commit('setloader', false)
                    if (error.response) {
                        if(error.response.data){
                            if(error.response.data.msg){
                                commit('seterror', error.response.data.msg)
                            }else if(error.response.data.message){
                                commit('seterror', error.response.data.message)
                            }
                            
                        }
                    }
                    reject()
                });
            });
                    
        },


        //websettings
        async updatewebsettings( { commit }, data) {
                commit('seterror', '')
                commit('setmsg', '')
                commit('setloader', 'updatewebsettings')
                return new Promise((resolve, reject) => {
                axios.post("admin/settings/update", data)
                .then(response => {	
                    commit('setloader', false)
                    if(response.data.res){
                        commit('setwebsetting',response.data.res)
                        
                    }
                    resolve()
                })
                .catch(error=> {
                    commit('setloader', false)
                    if (error.response) {
                        if(error.response.data){
                            if(error.response.data.msg){
                                commit('seterror', error.response.data.msg)
                            }else if(error.response.data.message){
                                commit('seterror', error.response.data.message)
                            }
                            
                        }
                    }
                    reject()
                });
            });
                    
        },







    
},

mutations : {
	setbanners: (state, banners) => (state.banners = banners),
	setbanner :(state,banner ) => (state.banner = banner), 
    settestimonies: (state, testimonies) => (state.testimonies = testimonies),
	settestimony :(state,testimony ) => (state.testimony = testimony), 
    setgallery:(state,gallery ) => (state.gallery = gallery), 
    setgalleries :(state,galleries ) => (state.galleries = galleries), 
    setclient :(state,client ) => (state.client = client), 
    setclients :(state,clients ) => (state.clients = clients),
    setteam :(state,team ) => (state.team = team), 
    setteams :(state,teams ) => (state.teams = teams),
    setwebsetting :(state,websetting  ) => (state.websetting  = websetting ),

},
}