export const namespaced = true 
import axios from 'axios'
import router from '@/router'
//import store from '@/store'

export default {
    state : {
        events :"",
        event : '', 
    },
    
    getters : {
        events: (state )=> state.events,
		event: (state )=> state.event,
    },


actions : {

	async searchevents( { commit }, data) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', 'saveevent')
        return new Promise((resolve, reject) => {
		axios.post("admin/events/search",  data)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				commit('setevents',response.data.res)
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('seterror', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });
		
			
	},


    async saveevent( { commit }, data) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', 'saveevent')
        return new Promise((resolve, reject) => {
		axios.post("admin/events/create",  data)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				commit('setevents',response.data.res)
				router.push({ name: 'allevents' });
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('seterror', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });
		
			
	},

    async updateevent( { commit }, {data, id}) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', id)
        return new Promise((resolve, reject) => {
		axios.post("admin/events/update/"+ id ,  data)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				commit('setevents',response.data.res)
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('seterror', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });
				
	},

    async updateeventimage( { commit }, {data, id}) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', id)
        return new Promise((resolve, reject) => {
		axios.post("admin/events/updateimage/"+ id ,  data)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				commit('setevent',response.data.res)
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('seterror', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });
				
	},

	async getallevents( { commit }, page) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', 'saveevent')
        return new Promise((resolve, reject) => {
		axios.get("admin/events", page)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				commit('setevents',response.data.res)
				
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('seterror', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });
		
			
	},

	async getevent( { commit }, id) {
			commit('seterror', '')
			commit('setmsg', '')
			commit('setloader', id)
			return new Promise((resolve, reject) => {
			axios.get("admin/events/" + id)
			.then(response => {	
				commit('setloader', false)
				if(response.data.res){
					commit('setevent',response.data.res)	
				}
				resolve()
			})
			.catch(error=> {
				commit('setloader', false)
				if (error.response) {
					if(error.response.data){
						if(error.response.data.msg){
							commit('seterror', error.response.data.msg)
						}else if(error.response.data.message){
							commit('seterror', error.response.data.message)
						}
						
					}
				}
				reject()
			});
		});
			
				
	},


	async changeeventstatus( { commit }, id) {
			commit('seterror', '')
			commit('setmsg', '')
			commit('setloader', id)
			return new Promise((resolve, reject) => {
			axios.get("admin/events/status/" + id)
			.then(response => {	
				commit('setloader', false)
				if(response.data.res){
					commit('setevents',response.data.res)
					
				}
				resolve()
			})
			.catch(error=> {
				commit('setloader', false)
				if (error.response) {
					if(error.response.data){
						if(error.response.data.msg){
							commit('seterror', error.response.data.msg)
						}else if(error.response.data.message){
							commit('seterror', error.response.data.message)
						}
						
					}
				}
				reject()
			});
		});
				
	},

	async deleteevent( { commit }, id) {
			commit('seterror', '')
			commit('setmsg', '')
			commit('setloader', id)
			return new Promise((resolve, reject) => {
			axios.get("admin/events/delete/" + id)
			.then(response => {	
				commit('setloader', false)
				if(response.data.res){
					commit('setevents',response.data.res)
					
				}
				resolve()
			})
			.catch(error=> {
				commit('setloader', false)
				if (error.response) {
					if(error.response.data){
						if(error.response.data.msg){
							commit('seterror', error.response.data.msg)
						}else if(error.response.data.message){
							commit('seterror', error.response.data.message)
						}
						
					}
				}
				reject()
			});
		});
				
	},
},

mutations : {
	setevents: (state, events) => (state.events = events),
	setevent :(state,event ) => (state.event = event), 

},
}