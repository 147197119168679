<template>
<div class="row">

<div class="col-xl-9 mb-3">

<h1 class="page-header">
Manage events From Here 
</h1>
<hr class="mb-4" />


<div class="card">
<ul class="nav nav-tabs nav-tabs-v2 px-4">
<li class="nav-item me-3"><a href="#allTab" class="nav-link active px-2" data-bs-toggle="tab">All</a></li>
<li class="nav-item me-3"><a href="#publishedTab" class="nav-link px-2" @click.prevent="changefilterlist('active')">Active events</a></li>
<li class="nav-item me-3"><a href="#expiredTab" class="nav-link px-2" @click.prevent="changefilterlist('suspended')">Suspended events</a></li>
</ul>


<div class="tab-content p-4">
<div class="tab-pane fade show active" id="allTab">

<div class="input-group mb-4">
<div class="flex-fill position-relative">
<div class="input-group">
<input type="text" v-model.trim="search" class="form-control ps-35px" placeholder="Search events by Title" />
<div class="input-group-text position-absolute top-0 bottom-0 bg-none border-0" style="z-index: 1020;">
<i class="fa fa-search opacity-5"></i>
</div>
</div>
</div>
</div>


<div class="table-responsive">
<table class="table table-hover text-nowrap">
<thead>
<tr>
<th class="border-top-0 pt-0 pb-2">#</th>
<th class="border-top-0 pt-0 pb-2">Event Title</th>
<th class="border-top-0 pt-0 pb-2">Event Date</th>

<th class="border-top-0 pt-0 pb-2">Views</th>
<th class="border-top-0 pt-0 pb-2">Comments</th>
<th class="border-top-0 pt-0 pb-2">status</th>
<th class="border-top-0 pt-0 pb-2">Action</th>
</tr>
</thead>
<tbody>

<tr v-for="(event, index) in filteredeventlist" :key="event.id" style="cursor:pointer !important" >

    <td class="align-middle">{{ index + 1}}</td>
    <td class="align-middle">{{ event.title }}</td>
    <td class="align-middle" >{{ event.on_date }}</td>
    <td class="align-middle" >{{ event.views_count }}</td>
    <td class="align-middle" >{{ event.comments_count }}</td>
    <td class="align-middle" >
        <button v-if="event.status == 1" @click.prevent="changeeventstatusbtn(event.id)" class="badge btn btn-teal  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2">Suspend</button>
        <button v-if="event.status == 2" @click.prevent="changeeventstatusbtn(event.id)" class="badge btn btn-indigo  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2">Unsuspend</button>
    </td>


    <td class="align-middle">
  
    <button @click.prevent="updateeventact(event.id)"  class="badge btn btn-warning  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2" style="margin-right:6px">Update</button>
    <button @click.prevent="deleteeventact(event.id)"   class="badge btn btn-danger  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2">Delete</button>

    </td>

</tr>




</tbody>
</table>
</div>


<div class="d-md-flex align-items-center" v-if="events.data">
<div class="me-md-auto text-md-left text-center mb-2 mb-md-0">
Showing {{ events.data.length}} to {{ events.per_page}} of {{ events.total}} entries
</div>
<ul class="pagination mb-0 justify-content-center">

<li class="page-item" v-for="(link, index) in events.links" :key="link.id">
    <a v-if="events.last_page >= index && index != 0 " @click.prevent="getpageteachers(index )" class="page-link" href="#">{{ index}}</a>
</li>

</ul>
</div>



</div>
</div>
</div>



</div>


<div class="col-xl-3">
    <nav id="sidebar-bootstrap" class="navbar navbar-sticky d-none d-xl-block">
<nav class="nav">
<router-link :to="{name:'addevent'}" class="menu-link btn btn-primary btn-sm">Add event</router-link>
<!-- <a class="nav-link" href="#formControls" data-toggle="scroll-to">Add event</a> -->
</nav>
</nav>


</div>

</div>


</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default{


    data(){
        return{
            lastpage:'',
            currentpage:'',
            nextpage:'',
            filtertype:'',
            search:''
            
        }

    },


    watch:{
		search(){
			if(this.search.length){
				if(this.search.length > 3){
					this.searchbtnevents()
				}else{
					this.getallevents()
				}

			}else{
				this.getallevents()
			}
		}
	},


    computed:{
        ...mapGetters(['events']),


        filteredeventlist(){
            if(this.filtertype == 'active'){
                return this.events.data.filter(co => co.status == 1)
            }else if(this.filtertype == 'suspended'){
                return this.events.data.filter(co => co.status == 2)
            }else {
                return this.events.data
            }
        },

    },


    methods:{
        ...mapActions(['getallevents', 'changeeventstatus','deleteevent','getevent','updateevent','searchevents']),

        changefilterlist(value){
            this.filtertype = value;
        },



        changeeventstatusbtn(id){
            this.changeeventstatus(id)
        },


        updateeventact(id){
            this.getevent(id).then(()=>{
                this.$router.push({ name: 'updateevent', params:{id:id}});
            })
        },

        deleteeventact(id){
            this.deleteevent(id).then(()=>{

            })
        },


        searchbtnevents(){
            let data = {
                search:this.search
            }
            this.searchevents(data).then(()=>{

            })
	    },
        


    },

    created(){
        this.getallevents()
    }
}
</script>
