//import router from '@/router'
// import store from '@/store'

let routes = [
    { 
        path: "/login",
        props: true,
      
        component: () => import("@/views/auth/login.vue"),
        title: 'Login Dash',
          children: [

            {
            path: "/login",
                name: "login",
                props:true,
                component: () => import("@/views/auth/login.vue"),
                meta: {
                title: 'Login'
                }
            },

          ]
        },


    ]

export default routes;