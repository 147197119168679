export const namespaced = true 
import axios from 'axios'
import router from '@/router'
//import store from '@/store'

export default {
    state : {
        medias :"",
        media : '', 
        uploadPercentage:0,
    },
    
    getters : {
        medias: (state )=> state.medias,
		media: (state )=> state.media,
        uploadPercentage: (state )=> state.uploadPercentage,
    },


actions : {

	async searchmedias( { commit }, data) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', 'savemedia')
        return new Promise((resolve, reject) => {
		axios.post("admin/medias/search",  data)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				commit('setmedias',response.data.res)
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('seterror', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });
		
			
	},


    async savemedia( { commit }, data) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', 'savemedia')
        return new Promise((resolve, reject) => {
		axios.post("admin/medias/create",  data,
        {
            onUploadProgress: function( progressEvent ) {
              var newuploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
              commit('setuploadPercentage', newuploadPercentage )
            }.bind(this)
           
          },)
		.then(response => {	
			commit('setloader', false)
            commit('setuploadPercentage', 0 )
			if(response.data.res){
				commit('setmedias',response.data.res)
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('seterror', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });
		
			
	},

    async updatemedia( { commit }, {data, id}) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', id)
        return new Promise((resolve, reject) => {
		axios.post("admin/medias/update/"+ id ,  data)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				commit('setmedias',response.data.res)
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('seterror', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });
				
	},

    async updatemediapic( { commit }, {data, id}) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', id)
        return new Promise((resolve, reject) => {
		axios.post("admin/medias/updateimage/"+ id ,  data)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				commit('setmedia',response.data.res)
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('seterror', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });
				
	},

	async getallmedias( { commit }, page) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', 'savemedia')
        return new Promise((resolve, reject) => {
		axios.get("admin/medias", page)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				commit('setmedias',response.data.res)
				
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('seterror', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });
		
			
	},

	async getmedia( { commit }, id) {
			commit('seterror', '')
			commit('setmsg', '')
			commit('setloader', id)
			return new Promise((resolve, reject) => {
			axios.get("admin/medias/" + id)
			.then(response => {	
				commit('setloader', false)
				if(response.data.res){
					commit('setmedia',response.data.res)	
				}
				resolve()
			})
			.catch(error=> {
				commit('setloader', false)
				if (error.response) {
					if(error.response.data){
						if(error.response.data.msg){
							commit('seterror', error.response.data.msg)
						}else if(error.response.data.message){
							commit('seterror', error.response.data.message)
						}
						
					}
				}
				reject()
			});
		});
			
				
	},


	async changemediastatus( { commit }, id) {
			commit('seterror', '')
			commit('setmsg', '')
			commit('setloader', id)
			return new Promise((resolve, reject) => {
			axios.get("admin/medias/status/" + id)
			.then(response => {	
				commit('setloader', false)
				if(response.data.res){
					commit('setmedias',response.data.res)
					
				}
				resolve()
			})
			.catch(error=> {
				commit('setloader', false)
				if (error.response) {
					if(error.response.data){
						if(error.response.data.msg){
							commit('seterror', error.response.data.msg)
						}else if(error.response.data.message){
							commit('seterror', error.response.data.message)
						}
						
					}
				}
				reject()
			});
		});
				
	},

	async deletemedia( { commit }, id) {
			commit('seterror', '')
			commit('setmsg', '')
			commit('setloader', id)
			return new Promise((resolve, reject) => {
			axios.get("admin/medias/delete/" + id)
			.then(response => {	
				commit('setloader', false)
				if(response.data.res){
					commit('setmedias',response.data.res)
					
				}
				resolve()
			})
			.catch(error=> {
				commit('setloader', false)
				if (error.response) {
					if(error.response.data){
						if(error.response.data.msg){
							commit('seterror', error.response.data.msg)
						}else if(error.response.data.message){
							commit('seterror', error.response.data.message)
						}
						
					}
				}
				reject()
			});
		});
				
	},


    
},

mutations : {
	setmedias: (state, medias) => (state.medias = medias),
	setmedia :(state,media ) => (state.media = media), 
    setuploadPercentage: (state, uploadPercentage) => (state.uploadPercentage = uploadPercentage),

},
}