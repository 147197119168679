<template>
    <div id="app" class="app app-full-height app-without-header">

<div class="login" >

<div class="login-content " style="border: 3px Solid #fff; padding:50px; border-radius:8px; box-shadow: 0 0 2px 1px #1F6BFF;">
<form action="https://seantheme.com/studio/index.html" method="POST" name="login_form">
<h1 class="text-center">Welcome to STI</h1>
<div class="text-muted text-center mb-4">
For our information protection, please verify your identity.
</div>
<div class="mb-3">
<label class="form-label">Select Country</label>
<select v-model="forminput.name" class="form-select select select2 form-control-lg fs-15px" >
 <option value="Uganda" selected>Uganda</option>
 <option value="South Sudan">South Sudan</option>
</select>

  <i class="text-danger" v-if="formerror.name">{{ formerror.name }}</i>
</div>
<div class="mb-3">
<div class="d-flex">
<label class="form-label">Password</label>
<a href="#" class="ms-auto text-muted">Forgot password?</a>
</div>
<input type="password" v-model="forminput.password" class="form-control form-control-lg fs-15px"  placeholder="Enter your password" />
<i class="text-danger" v-if="formerror.password">{{ formerror.password }}</i>
</div>
<div class="mb-3">
<div class="form-check">
<input class="form-check-input" v-model="forminput.remember_me"  type="checkbox"  id="customCheck1" />
<label class="form-check-label fw-500" for="customCheck1">Remember me</label>
</div>
</div>
<div class="mb-3 text-center">
<span class="text-danger" v-if="error">{{ error }}</span>
<span class="text-danger" v-if="apierror">{{ apierror }}</span>
</div>

<button v-if="loading == 'login'" type="submit" class="btn btn-primary btn-lg d-block w-100 fw-500 mb-3" @click.prevent="loginuserdata">
<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  Loading...
</button>
<button v-else type="submit" class="btn btn-primary btn-lg d-block w-100 fw-500 mb-3" @click.prevent="loginuserdata">Sign In</button>

</form>
</div>

</div>


<a href="#" data-click="scroll-top" class="btn-scroll-top fade"><i class="fa fa-arrow-up"></i></a>


</div>
</template>
<script>
import {mapGetters , mapActions } from 'vuex'
export default {

data(){
    return{
        forminput:{
            name:'',
            password:"",
            remember_me:""
        },

        formerror:{
            name:'',
            password:"",
            remember_me:""
        }
    }
},


computed:{
    ...mapGetters(['msg', 'apierror','error','loading'])
},


methods:{
 ...mapActions(['loginuser']),


 loginuserdata(){
    if(this.forminput.name == ""){
        this.formerror.name = 'Please Select a Country';
        setTimeout(() => this.formerror.name = '', 3000)
    }else if(this.forminput.password == ""){
        this.formerror.password = 'Please Enter your Password';
        setTimeout(() => this.formerror.password = '', 3000)
    }else{

        let data ={
            name:this.forminput.name,
            password:this.forminput.password,
            remember_me:this.forminput.remember_me
        }


        this.loginuser(data).then(()=>{

        })

    }
 }
}

    
}
</script>