<template>
<div class="row">

<div class="col-xl-9 mb-3">

<h1 class="page-header">
Manage Blogs From Here 
</h1>
<hr class="mb-4" />


<div class="card">
<ul class="nav nav-tabs nav-tabs-v2 px-4">
<li class="nav-item me-3"><a href="#allTab" class="nav-link active px-2" data-bs-toggle="tab">All</a></li>
<li class="nav-item me-3"><a href="#publishedTab" class="nav-link px-2" @click.prevent="changefilterlist('active')">Active Blogs</a></li>
<li class="nav-item me-3"><a href="#expiredTab" class="nav-link px-2" @click.prevent="changefilterlist('suspended')">Suspended Blogs</a></li>
</ul>


<div class="tab-content p-4">
<div class="tab-pane fade show active" id="allTab">

<div class="input-group mb-4">
<div class="flex-fill position-relative">
<div class="input-group">
<input type="text" v-model.trim="search" class="form-control ps-35px" placeholder="Search Blogs by Title" />
<div class="input-group-text position-absolute top-0 bottom-0 bg-none border-0" style="z-index: 1020;">
<i class="fa fa-search opacity-5"></i>
</div>
</div>
</div>
</div>


<div class="table-responsive">
<table class="table table-hover text-nowrap">
<thead>
<tr>
<th class="border-top-0 pt-0 pb-2">#</th>
<th class="border-top-0 pt-0 pb-2">Blog Title</th>
<th class="border-top-0 pt-0 pb-2">Blog Image Address</th>
<th class="border-top-0 pt-0 pb-2">Views</th>
<th class="border-top-0 pt-0 pb-2">Comments</th>
<th class="border-top-0 pt-0 pb-2">status</th>
<th class="border-top-0 pt-0 pb-2">Action</th>
</tr>
</thead>
<tbody>

<tr v-for="(blog, index) in filteredbloglist" :key="blog.id" style="cursor:pointer !important" >

    <td class="align-middle">{{ index + 1}}</td>
    <td class="align-middle">{{ blog.title }}</td>
    <td class="align-middle" ><a href="">{{ blog.image }}</a></td>
    <td class="align-middle" >{{ blog.views_count }}</td>
    <td class="align-middle" >{{ blog.comments_count }}</td>
    <td class="align-middle" >
        <button v-if="blog.status == 1" @click.prevent="changeblogstatusbtn(blog.id)" class="badge btn btn-teal  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2">Suspend</button>
        <button v-if="blog.status == 2" @click.prevent="changeblogstatusbtn(blog.id)" class="badge btn btn-indigo  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2">Unsuspend</button>
    </td>


    <td class="align-middle">
  
    <button @click.prevent="updateblogact(blog.id)"  class="badge btn btn-warning  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2" style="margin-right:6px">Update</button>
    <button @click.prevent="deleteblogact(blog.id)"   class="badge btn btn-danger  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2">Delete</button>

    </td>

</tr>




</tbody>
</table>
</div>


<div class="d-md-flex align-items-center" v-if="blogs.data">
<div class="me-md-auto text-md-left text-center mb-2 mb-md-0">
Showing {{ blogs.data.length}} to {{ blogs.per_page}} of {{ blogs.total}} entries
</div>
<ul class="pagination mb-0 justify-content-center">

<li class="page-item" v-for="(link, index) in blogs.links" :key="link.id">
    <a v-if="blogs.last_page >= index && index != 0 " @click.prevent="getpageteachers(index )" class="page-link" href="#">{{ index}}</a>
</li>

</ul>
</div>



</div>
</div>
</div>



</div>


<div class="col-xl-3">
    <nav id="sidebar-bootstrap" class="navbar navbar-sticky d-none d-xl-block">
<nav class="nav">
<router-link :to="{name:'addblog'}" class="menu-link btn btn-primary btn-sm">Add blog</router-link>
<!-- <a class="nav-link" href="#formControls" data-toggle="scroll-to">Add Blog</a> -->
</nav>
</nav>


</div>

</div>


</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default{


    data(){
        return{
            lastpage:'',
            currentpage:'',
            nextpage:'',
            filtertype:'',
            search:''
            
        }

    },


    watch:{
		search(){
			if(this.search.length){
				if(this.search.length > 3){
					this.searchbtnblogs()
				}else{
					this.getallblogs()
				}

			}else{
				this.getallblogs()
			}
		}
	},


    computed:{
        ...mapGetters(['blogs']),


        filteredbloglist(){
            if(this.filtertype == 'active'){
                return this.blogs.data.filter(co => co.status == 1)
            }else if(this.filtertype == 'suspended'){
                return this.blogs.data.filter(co => co.status == 2)
            }else {
                return this.blogs.data
            }
        },

    },


    methods:{
        ...mapActions(['getallblogs', 'changeblogstatus','deleteblog','getblog','updateblog','searchblogs']),

        changefilterlist(value){
            this.filtertype = value;
        },



        changeblogstatusbtn(id){
            this.changeblogstatus(id)
        },


        updateblogact(id){
            this.getblog(id).then(()=>{
                this.$router.push({ name: 'updateblog', params:{id:id}});
            })
        },

        deleteblogact(id){
            this.deleteblog(id).then(()=>{

            })
        },


        searchbtnblogs(){
            let data = {
                search:this.search
            }
            this.searchblogs(data).then(()=>{

            })
	    },
        


    },

    created(){
        this.getallblogs()
    }
}
</script>
