<template>
<h1 class="page-header">
Manage Webiste Client From Here 
</h1>
<hr class="mb-4" />

<div class="card  bg-default border-primary  mb-4">
    <div class="card-header"><h5 class="card-title">Update Website Contact Information</h5></div>
    <div class="card-body" @keyup.prevent="updatewebsettingsaction">
        <div class="form-group row mb-3">
        
        <div class="col-sm-6 mb-2">
            <label class="form-label" for="exampleFormControlInput1">Main Phone Number</label>
            <input type="email" v-model="websetting.contacts.phone" class="form-control" id="exampleFormControlInput1" placeholder="Phone Number">
        </div>

        <div class="col-sm-6  mb-2">
           <label class="form-label" for="exampleFormControlInput1">Main Email Address</label>
            <input type="email" v-model="websetting.contacts.email" class="form-control" id="exampleFormControlInput1" placeholder="Phone Number">
        </div>

        <div class="col-sm-6  mb-2">
           <label class="form-label" for="exampleFormControlInput1">Main Address or Location</label>
            <input type="email" v-model="websetting.contacts.address" class="form-control" id="exampleFormControlInput1" placeholder="Phone Number">
        </div>

        <div class="col-sm-12 mt-4">
            <button v-if="loading == 'updatewebsettings'" type="submit" class="btn btn-primary btn-lg d-block w-100 fw-500 mb-3" @click.prevent="updatewebsettingsaction">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Updating...
            </button>
            <button v-else type="button" class="btn btn-indigo btn-block" style="width:100%" @click.prevent="updatewebsettingsaction">Waiting....</button>
        </div>
        </div>
    </div>
</div>


<div class="card  bg-default border-primary  mb-4">
    <div class="card-header"><h5 class="card-title">Update Website Social Information</h5></div>
    <div class="card-body" @keyup.prevent="updatewebsettingsaction">
        <div class="form-group row mb-3">
        
        <div class="col-sm-6 mb-2">
            <label class="form-label" for="exampleFormControlInput1">Facebook Link</label>
            <input type="email" v-model="websetting.socials.facebook" class="form-control" id="exampleFormControlInput1" placeholder="Phone Number">
        </div>

        <div class="col-sm-6 mb-2">
           <label class="form-label" for="exampleFormControlInput1">Youtube Channel</label>
            <input type="email" v-model="websetting.socials.youtube" class="form-control" id="exampleFormControlInput1" placeholder="Phone Number">
        </div>

        <div class="col-sm-4  mb-2">
           <label class="form-label" for="exampleFormControlInput1">Instagram Link</label>
            <input type="email" v-model="websetting.socials.instagram" class="form-control" id="exampleFormControlInput1" placeholder="Phone Number">
        </div>

         <div class="col-sm-4  mb-2">
           <label class="form-label" for="exampleFormControlInput1">Twiiter Link</label>
            <input type="email" v-model="websetting.socials.twitter" class="form-control" id="exampleFormControlInput1" placeholder="Phone Number">
        </div>

        <div class="col-sm-4  mb-2">
           <label class="form-label" for="exampleFormControlInput1">Linkedin Link</label>
            <input type="email" v-model="websetting.socials.linkedin" class="form-control" id="exampleFormControlInput1" placeholder="Phone Number">
        </div>


        <div class="col-sm-12 mt-4">
            <button v-if="loading == 'updatewebsettings'" type="submit" class="btn btn-primary btn-lg d-block w-100 fw-500 mb-3" @click.prevent="updatewebsettingsaction">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Updating...
            </button>
            <button v-else type="button" class="btn btn-indigo btn-block" style="width:100%" @click.prevent="updatewebsettingsaction">Waiting....</button>
        </div>
        </div>
    </div>
</div>


<div class="card  bg-default border-primary  mb-4">
    <div class="card-header"><h5 class="card-title">Update Website About Information</h5></div>
    <div class="card-body" >
        <div class="form-group row mb-3">
        
        <div class="col-sm-6 mb-2">
            <label class="form-label" for="exampleFormControlInput1">Welcome About Text</label>
            <editor v-model="websetting.about.welcometext"
                api-key="y2537ncsedjdv72e6n9m0lr7lw07recoqjscjoi098syu085"
                :init="{
                menubar: false,
                plugins: 'a11ychecker advcode casechange export formatpainter image editimage linkchecker autolink lists checklist media mediaembed pageembed permanentpen powerpaste table advtable tableofcontents  tinymcespellchecker',
                toolbar: 'styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | link image emoticons a11ycheck addcomment showcomments casechange checklist code export formatpainter image editimage pageembed permanentpen table tableofcontents'}"
            />
        </div>

        <div class="col-sm-6 mb-2">
            <label class="form-label" for="exampleFormControlInput1">Roles  Text</label>
            <editor v-model="websetting.about.mainroles"
                api-key="y2537ncsedjdv72e6n9m0lr7lw07recoqjscjoi098syu085"
                :init="{
                menubar: false,
                plugins: 'a11ychecker advcode casechange export formatpainter image editimage linkchecker autolink lists checklist media mediaembed pageembed permanentpen powerpaste table advtable tableofcontents  tinymcespellchecker',
                toolbar: 'styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | link image emoticons a11ycheck addcomment showcomments casechange checklist code export formatpainter image editimage pageembed permanentpen table tableofcontents'}"
            />
          
        </div>

        <div class="col-sm-12 mt-4">
            <button v-if="loading == 'updatewebsettings'" type="submit" class="btn btn-primary btn-lg d-block w-100 fw-500 mb-3" @click.prevent="updatewebsettingsaction">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Updating...
            </button>
            <button v-else type="button" class="btn btn-indigo btn-block" style="width:100%" @click.prevent="updatewebsettingsaction">Update Now</button>
        </div>
        </div>
    </div>
</div>
</template>
<script>
import {mapGetters, mapActions } from 'vuex'
import Editor from '@tinymce/tinymce-vue'
export default{

    components: {
    'editor': Editor
    },

    computed:{
        ...mapGetters(['websetting', 'loading'])
    },

    methods:{
      ...mapActions(['getwebsettings','updatewebsettings']),
      
      updatewebsettingsaction(){
        let data = {
            phone:this.websetting.contacts.phone,
            email:this.websetting.contacts.email,
            address:this.websetting.contacts.address,

            phone:this.websetting.socials.facebook,
            youtube:this.websetting.socials.youtube,
            instagram:this.websetting.socials.instagram,
            twitter:this.websetting.socials.twitter,
            linkedin:this.websetting.socials.linkedin,

            welcometext:this.websetting.about.welcometext,
            mainroles:this.websetting.about.mainroles,
        }
        this.updatewebsettings(data);

      }
    },

    created(){
        this.getwebsettings();
    }
}
</script>