import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLs from 'secure-ls'
const ls = new SecureLs({isCompression:false});


import auth  from './modules/auth'
import blogs  from './modules/blogs'
import whatwedo  from './modules/whatwedo'
import media  from './modules/media'
import webmanager  from './modules/webmanager.js'
import careers  from './modules/careers.js'
import events  from './modules/events.js'
export default createStore({
  modules: {
    auth,
    blogs,
    whatwedo,
    media,
    webmanager,
    careers,
    events
    
  },
    
  plugins: [createPersistedState(
    {
      key : 'www.admin.scienceteacherinitiative.org',
      paths: ['auth'],
      storage:{
         getItem : (key) => ls.get(key),
         setItem: (key, value) => ls.set(key, value),
         removeItem: (key) => ls.remove(key)
      }
    }
  )],
  
})
