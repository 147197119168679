<template>
<h1 class="page-header mb-3">
Welcome to  STI Website Management  tool
</h1>

<div class="row">

<div class="col-xl-8 mb-3">



</div>


<div class="col-xl-4">



</div>

</div>


</template>