export const namespaced = true 
import axios from 'axios'
import router from '@/router'
//import store from '@/store'

export default {
    state : {
        careers :"",
        career : '', 
    },
    
    getters : {
        careers: (state )=> state.careers,
		career: (state )=> state.career,
    },


actions : {

	async searchcareers( { commit }, data) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', 'savecareer')
        return new Promise((resolve, reject) => {
		axios.post("admin/careers/search",  data)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				commit('setcareers',response.data.res)
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('seterror', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });
		
			
	},


    async savecareer( { commit }, data) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', 'savecareer')
        return new Promise((resolve, reject) => {
		axios.post("admin/careers/create",  data)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				commit('setcareers',response.data.res)
				router.push({ name: 'allcareers' });
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('seterror', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });
		
			
	},

    async updatecareer( { commit }, {data, id}) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', id)
        return new Promise((resolve, reject) => {
		axios.post("admin/careers/update/"+ id ,  data)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				commit('setcareers',response.data.res)
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('seterror', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });
				
	},

    async updatecareerimage( { commit }, {data, id}) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', id)
        return new Promise((resolve, reject) => {
		axios.post("admin/careers/updateimage/"+ id ,  data)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				commit('setcareer',response.data.res)
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('seterror', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });
				
	},



    async updatecareerfile( { commit }, {data, id}) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', id)
        return new Promise((resolve, reject) => {
		axios.post("admin/careers/updatefile/"+ id ,  data)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				commit('setcareer',response.data.res)
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('seterror', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });
				
	},

	async getallcareers( { commit }, page) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', 'savecareer')
        return new Promise((resolve, reject) => {
		axios.get("admin/careers", page)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				commit('setcareers',response.data.res)
				
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('seterror', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });
		
			
	},

	async getcareer( { commit }, id) {
			commit('seterror', '')
			commit('setmsg', '')
			commit('setloader', id)
			return new Promise((resolve, reject) => {
			axios.get("admin/careers/" + id)
			.then(response => {	
				commit('setloader', false)
				if(response.data.res){
					commit('setcareer',response.data.res)	
				}
				resolve()
			})
			.catch(error=> {
				commit('setloader', false)
				if (error.response) {
					if(error.response.data){
						if(error.response.data.msg){
							commit('seterror', error.response.data.msg)
						}else if(error.response.data.message){
							commit('seterror', error.response.data.message)
						}
						
					}
				}
				reject()
			});
		});
			
				
	},


	async changecareerstatus( { commit }, id) {
			commit('seterror', '')
			commit('setmsg', '')
			commit('setloader', id)
			return new Promise((resolve, reject) => {
			axios.get("admin/careers/status/" + id)
			.then(response => {	
				commit('setloader', false)
				if(response.data.res){
					commit('setcareers',response.data.res)
					
				}
				resolve()
			})
			.catch(error=> {
				commit('setloader', false)
				if (error.response) {
					if(error.response.data){
						if(error.response.data.msg){
							commit('seterror', error.response.data.msg)
						}else if(error.response.data.message){
							commit('seterror', error.response.data.message)
						}
						
					}
				}
				reject()
			});
		});
				
	},

	async deletecareer( { commit }, id) {
			commit('seterror', '')
			commit('setmsg', '')
			commit('setloader', id)
			return new Promise((resolve, reject) => {
			axios.get("admin/careers/delete/" + id)
			.then(response => {	
				commit('setloader', false)
				if(response.data.res){
					commit('setcareers',response.data.res)
					
				}
				resolve()
			})
			.catch(error=> {
				commit('setloader', false)
				if (error.response) {
					if(error.response.data){
						if(error.response.data.msg){
							commit('seterror', error.response.data.msg)
						}else if(error.response.data.message){
							commit('seterror', error.response.data.message)
						}
						
					}
				}
				reject()
			});
		});
				
	},
},

mutations : {
	setcareers: (state, careers) => (state.careers = careers),
	setcareer :(state,career ) => (state.career = career), 

},
}