<template>
<div class="row">

<div class="col-xl-12 mb-3">

<h1 class="page-header">
Manage Media From Here 
</h1>
<hr class="mb-4" />

<div class="card text-white bg-primary mb-4">
    <div class="card-header"><h5 class="card-title">Add New Media to Database</h5></div>
    <div class="card-body">
        <div class="form-group row mb-3">
        
        <div class="col-sm-4">
            <label class="form-label" for="exampleFormControlInput1">Enter File Title</label>
            <input type="email" v-model="forminput.title" class="form-control" id="exampleFormControlInput1" placeholder="Enter File Title">
            <i class="text-warning" v-if="formerror.title">{{ formerror.title }}</i>
        </div>

        <div class="col-sm-3">
            <label class="form-label"  for="exampleFormControlInput1">Select Type</label>
            <select class="form-control" v-model="forminput.type">
                <option value="doc">Document</option>
                <option value="image">Image</option>
                <option value="video">Video</option>
                <option value="audio">Audio</option>
                <option value="other">Others</option>
            </select>
            <i class="text-warning" v-if="formerror.type">{{ formerror.type }}</i>
        </div>
       
        <div class="col-sm-3">
            <label class="form-label" for="exampleFormControlInput1">Choose File</label>
            <input type="file" @change="selectedfileupload" class="form-control" id="exampleFormControlFile1" />
            <i class="text-warning" v-if="formerror.uploadfile">{{ formerror.uploadfile }}</i>
        </div>

        <div class="col-sm-2 mt-4">
            <button type="button" class="btn btn-indigo" @click.prevent="savemediaact">Submit File</button>
        </div>
        </div>

        <div class="progress" v-if="loading == 'savemedia'">
            <div class="progress-bar progress-bar-striped bg-indigo " :style="{ 'width': getuploadwidth}">{{ getuploadwidth }}</div>
        </div>
    </div>
</div>


<div class="card">
<ul class="nav nav-tabs nav-tabs-v2 px-4">
<li class="nav-item me-3"><a href="#" class="nav-link active px-2" data-bs-toggle="tab">All</a></li>
<li class="nav-item me-3"><a href="#" class="nav-link px-2" @click.prevent="changefilterlist('active')">Active </a></li>
<li class="nav-item me-3"><a href="#" class="nav-link px-2" @click.prevent="changefilterlist('suspended')">Suspended </a></li>
<li class="nav-item me-3"><a href="#" class="nav-link px-2" @click.prevent="changefilterlist('document')">Documents </a></li>
<li class="nav-item me-3"><a href="#" class="nav-link px-2" @click.prevent="changefilterlist('video')">Videos </a></li>
<li class="nav-item me-3"><a href="#" class="nav-link px-2" @click.prevent="changefilterlist('image')">Images </a></li>
<li class="nav-item me-3"><a href="#" class="nav-link px-2" @click.prevent="changefilterlist('audio')">Audios </a></li>
<li class="nav-item me-3"><a href="#" class="nav-link px-2" @click.prevent="changefilterlist('other')">Others </a></li>
</ul>


<div class="tab-content p-4">
<div class="tab-pane fade show active" id="allTab">

<div class="input-group mb-4">
<div class="flex-fill position-relative">
<div class="input-group">
<input type="text" v-model.trim="search" class="form-control ps-35px" placeholder="Search Media by Title" />
<div class="input-group-text position-absolute top-0 bottom-0 bg-none border-0" style="z-index: 1020;">
<i class="fa fa-search opacity-5"></i>
</div>
</div>
</div>
</div>


<div class="table-responsive" v-if="medias">
<table class="table table-hover text-nowrap">
<thead>
<tr>
<th class="border-top-0 pt-0 pb-2">#</th>
<th class="border-top-0 pt-0 pb-2">Title</th>
<th class="border-top-0 pt-0 pb-2">Size</th>
<th class="border-top-0 pt-0 pb-2">Type</th>
<th class="border-top-0 pt-0 pb-2">File Address(Click to Copy Link)</th>
<th class="border-top-0 pt-0 pb-2">status</th>
<th class="border-top-0 pt-0 pb-2">Action</th>
</tr>
</thead>
<tbody>

<tr v-for="(file, index) in filteredlist" :key="file.id" style="cursor:pointer !important" >

    <td class="align-middle">{{ index + 1}}</td>
    <td class="align-middle">{{ file.title }}</td>
    <td class="align-middle" >{{ (file.size/1000000).toFixed(2) }} MBs</td>
    <td class="align-middle" >{{ file.type }}</td>
    <td class="align-middle" ><button class="btn btn-pink btn-sm" @click.prevent="copyfilelinktoclip(file.file_short_url)">{{ file.file.substring(0,30) }}</button></td>
    <td class="align-middle" >
        <button v-if="file.status == 1" @click.prevent="changemediastatusbtn(file.id)" class="badge btn btn-teal  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2">Suspend</button>
        <button v-if="file.status == 2" @click.prevent="changemediastatusbtn(file.id)" class="badge btn btn-indigo  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2">Unsuspend</button>
    </td>


    <td class="align-middle">
  
    <button @click.prevent="downloadfile(file.file_url)"  class="badge btn btn-warning  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2" style="margin-right:6px">Download</button>
    <button @click.prevent="deletemediaact(file.id)"   class="badge btn btn-danger  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2">Delete</button>

    </td>

</tr>




</tbody>
</table>
</div>


<div class="d-md-flex align-items-center" v-if="medias.data">
<div class="me-md-auto text-md-left text-center mb-2 mb-md-0">
Showing {{ medias.data.length}} to {{ medias.per_page}} of {{ medias.total}} entries
</div>
<ul class="pagination mb-0 justify-content-center">

<li class="page-item" v-for="(link, index) in medias.links" :key="link.id">
    <a v-if="medias.last_page >= index && index != 0 " @click.prevent="getpagemedias(index )" class="page-link" href="#">{{ index}}</a>
</li>

</ul>
</div>



</div>
</div>
</div>



</div>


</div>


</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default{


    data(){
        return{
        forminput:{
            title:'',
            uploadfile:'',
            type:''
        },

        formerror:{
            title:'',
            uploadfile:'',
            type:''
        },

        lastpage:'',
        currentpage:'',
        nextpage:'',
        filtertype:'',
        search:''
            
        }

    },


    watch:{
		search(){
			if(this.search.length){
				if(this.search.length > 3){
					this.searchbtnmedias()
				}else{
					this.getallmedias()
				}

			}else{
				this.getallmedias()
			}
		}
	},


    computed:{
        ...mapGetters(['medias','uploadPercentage','loading']),

        getuploadwidth(){
            return this.uploadPercentage + "%";
        },


        filteredlist(){
            if(this.filtertype == 'active'){
                return this.medias.data.filter(co => co.status == 1)
            }else if(this.filtertype == 'suspended'){
                return this.medias.data.filter(co => co.status == 2)
            }else if(this.filtertype == 'document'){
                return this.medias.data.filter(co => co.type == 'doc')
            }else if(this.filtertype == 'image'){
                return this.medias.data.filter(co => co.type == 'image')
            }else if(this.filtertype == 'video'){
                return this.medias.data.filter(co => co.type == 'video')
            }else if(this.filtertype == 'audio'){
                return this.medias.data.filter(co => co.type == 'audio')
            }else if(this.filtertype == 'other'){
                return this.medias.data.filter(co => co.type == 'other')
            }else {
                return this.medias.data
            }
        },

    },


    methods:{
        ...mapActions(['getallmedias','savemedia', 'changemediastatus','deletemedia','updatemedia','searchmedias']),

        changefilterlist(value){
            this.filtertype = value;
        },

        selectedfileupload(e){
            this.forminput.uploadfile = e.target.files[0];
        },

        copyfilelinktoclip(value) {
            navigator.clipboard.writeText(value);
            alert("Link Coped Successfully " + value)

        },

        downloadfile(value){
            window.location.href = value;
        },

        savemediaact(){
        if(this.forminput.title == ''){
            this.formerror.title = 'Media Title is Required';
            setTimeout(() => this.formerror.title = '', 3000)
        }else if(this.forminput.type == ''){
            this.formerror.type = 'Media Type is Required';
            setTimeout(() => this.formerror.type = '', 3000)
        }else if(this.forminput.uploadfile == ''){
            this.formerror.uploadfile = 'An Attachment is Required';
            setTimeout(() => this.formerror.uploadfile = '', 3000)
        }else{
            let data = new FormData();
            data.append('title', this.forminput.title)
            data.append('type', this.forminput.type)
            data.append('file', this.forminput.uploadfile)
            this.savemedia(data).then(()=>{
                this.forminput.title = '';
                this.forminput.type = '';
                this.forminput.uploadfile = '';
            })
        }

            
        },

        changemediastatusbtn(id){
            this.changemediastatus(id)
        },


        updatemediaact(id){
            this.updatemedia(id).then(()=>{
               
            })
        },

        deletemediaact(id){
            this.deletemedia(id).then(()=>{

            })
        },


        searchbtnmedias(){
            let data = {
                search:this.search
            }
            this.searchmedias(data).then(()=>{

            })
	    },
        


    },

    created(){
        this.filtertype = "";
        this.getallmedias()
    }
}
</script>
