<template>
<div class="row">

<div class="col-xl-9 mb-3">

<h1 class="page-header">
Manage Careers From Here 
</h1>
<hr class="mb-4" />


<div class="card">
<ul class="nav nav-tabs nav-tabs-v2 px-4">
<li class="nav-item me-3"><a href="#allTab" class="nav-link active px-2" data-bs-toggle="tab">All</a></li>
<li class="nav-item me-3"><a href="#publishedTab" class="nav-link px-2" @click.prevent="changefilterlist('active')">Active Careers</a></li>
<li class="nav-item me-3"><a href="#expiredTab" class="nav-link px-2" @click.prevent="changefilterlist('suspended')">Suspended Careers</a></li>
</ul>


<div class="tab-content p-4">
<div class="tab-pane fade show active" id="allTab">

<div class="input-group mb-4">
<div class="flex-fill position-relative">
<div class="input-group">
<input type="text" v-model.trim="search" class="form-control ps-35px" placeholder="Search careers by Title" />
<div class="input-group-text position-absolute top-0 bottom-0 bg-none border-0" style="z-index: 1020;">
<i class="fa fa-search opacity-5"></i>
</div>
</div>
</div>
</div>


<div class="table-responsive">
<table class="table table-hover text-nowrap">
<thead>
<tr>
<th class="border-top-0 pt-0 pb-2">#</th>
<th class="border-top-0 pt-0 pb-2">career Title</th>
<th class="border-top-0 pt-0 pb-2">career Image Address</th>
<th class="border-top-0 pt-0 pb-2">Views</th>
<th class="border-top-0 pt-0 pb-2">Comments</th>
<th class="border-top-0 pt-0 pb-2">status</th>
<th class="border-top-0 pt-0 pb-2">Action</th>
</tr>
</thead>
<tbody>

<tr v-for="(career, index) in filteredcareerlist" :key="career.id" style="cursor:pointer !important" >

    <td class="align-middle">{{ index + 1}}</td>
    <td class="align-middle">{{ career.title }}</td>
    <td class="align-middle" ><a href="">{{ career.image }}</a></td>
    <td class="align-middle" >{{ career.views_count }}</td>
    <td class="align-middle" >{{ career.comments_count }}</td>
    <td class="align-middle" >
        <button v-if="career.status == 1" @click.prevent="changecareerstatusbtn(career.id)" class="badge btn btn-teal  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2">Suspend</button>
        <button v-if="career.status == 2" @click.prevent="changecareerstatusbtn(career.id)" class="badge btn btn-indigo  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2">Unsuspend</button>
    </td>


    <td class="align-middle">
  
    <button @click.prevent="updatecareeract(career.id)"  class="badge btn btn-warning  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2" style="margin-right:6px">Update</button>
    <button @click.prevent="deletecareeract(career.id)"   class="badge btn btn-danger  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2">Delete</button>

    </td>

</tr>




</tbody>
</table>
</div>


<div class="d-md-flex align-items-center" v-if="careers.data">
<div class="me-md-auto text-md-left text-center mb-2 mb-md-0">
Showing {{ careers.data.length}} to {{ careers.per_page}} of {{ careers.total}} entries
</div>
<ul class="pagination mb-0 justify-content-center">

<li class="page-item" v-for="(link, index) in careers.links" :key="link.id">
    <a v-if="careers.last_page >= index && index != 0 " @click.prevent="getpageteachers(index )" class="page-link" href="#">{{ index}}</a>
</li>

</ul>
</div>



</div>
</div>
</div>



</div>


<div class="col-xl-3">
    <nav id="sidebar-bootstrap" class="navbar navbar-sticky d-none d-xl-block">
<nav class="nav">
<router-link :to="{name:'addcareer'}" class="menu-link btn btn-primary btn-sm">Add career</router-link>
<!-- <a class="nav-link" href="#formControls" data-toggle="scroll-to">Add career</a> -->
</nav>
</nav>


</div>

</div>


</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default{


    data(){
        return{
            lastpage:'',
            currentpage:'',
            nextpage:'',
            filtertype:'',
            search:''
            
        }

    },


    watch:{
		search(){
			if(this.search.length){
				if(this.search.length > 3){
					this.searchbtncareers()
				}else{
					this.getallcareers()
				}

			}else{
				this.getallcareers()
			}
		}
	},


    computed:{
        ...mapGetters(['careers']),


        filteredcareerlist(){
            if(this.filtertype == 'active'){
                return this.careers.data.filter(co => co.status == 1)
            }else if(this.filtertype == 'suspended'){
                return this.careers.data.filter(co => co.status == 2)
            }else {
                return this.careers.data
            }
        },

    },


    methods:{
        ...mapActions(['getallcareers', 'changecareerstatus','deletecareer','getcareer','updatecareer','searchcareers']),

        changefilterlist(value){
            this.filtertype = value;
        },



        changecareerstatusbtn(id){
            this.changecareerstatus(id)
        },


        updatecareeract(id){
            this.getcareer(id).then(()=>{
                this.$router.push({ name: 'updatecareer', params:{id:id}});
            })
        },

        deletecareeract(id){
            this.deletecareer(id).then(()=>{

            })
        },


        searchbtncareers(){
            let data = {
                search:this.search
            }
            this.searchcareers(data).then(()=>{

            })
	    },
        


    },

    created(){
        this.getallcareers()
    }
}
</script>
