export const namespaced = true 
import axios from 'axios'
import router from '@/router'
//import store from '@/store'

export default {
    state : {
        authuser :'',
        authlocation : '',
        error :'',
        errors :[],
        apierror:'',
        msg :'',
        loading:'',
        pageloader:'',
        isAuth:'',
        
    },
    
    getters : {
        authuser: (state )=> state.authuser,
        authlocation: (state )=> state.authlocation,
        error: (state )=> state.error,
        errors: (state )=> state.errors,
        apierror: (state )=> state.apierror,
        msg: (state )=> state.msg,
        loading: (state )=> state.loading,
        pageloader: (state )=> state.pageloader,
        isAuth: state => !!state.authuser,
    },


actions : {

    async loginuser( { commit }, data) {
		localStorage.removeItem('stiweb_api_auth_key');
		window.localStorage.clear();
		localStorage.clear();
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', 'login')
        return new Promise((resolve, reject) => {
		axios.post("auth/login",  data)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				localStorage.setItem('stiweb_api_auth_key', response.data.authtoken.accessToken)
				localStorage.setItem('userlocation', response.data.location)
				commit('setauthuser', response.data.res)
				commit('setauthlocation', response.data.location)
				router.push({ name: 'welcome' });
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('seterror', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });
		
			
	},


},

mutations : {
	setToken: (state, token) => (state.token = token),
	setauthuser :(state,authuser ) => (state.authuser = authuser), 
	setauthlocation :(state,authlocation ) => (state.authlocation = authlocation), 
	setisAuth :(state,isAuth ) => (state.isAuth = isAuth), 
	setisVerified :(state,isVerified ) => (state.isVerified = isVerified),  
	setloader :(state,loading ) => (state.loading = loading), 
	setpageloader :(state,pageloader ) => (state.pageloader = pageloader), 
	seterror :(state,error ) => (state.error = error), 
	seterrors :(state,errors ) => (state.errors = errors), 
	setmsg :(state,msg ) => (state.msg = msg), 
	setapierror :(state,apierror ) => (state.apierror = apierror),
},
}