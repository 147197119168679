<template>
<h1 class="page-header">
Manage Webiste Gallery From Here 
</h1>
<hr class="mb-4" />

<div class="card  bg-default border-primary  mb-4" id="newgallerydata">
    <div class="card-header"><h5 class="card-title">Add New Gallary t to Database</h5></div>
    <div class="card-body">
        <div class="form-group row mb-3">
        
        <div class="col-sm-6">
            <label class="form-label" for="exampleFormControlInput1">Photo  Title</label>
            <input type="email" v-model="forminput.title" class="form-control" id="exampleFormControlInput1" placeholder=" Title">
            <i class="text-warning" v-if="formerror.title">{{ formerror.title }}</i>

            <div class="mt-4">
            <label class="form-label" for="exampleFormControlInput1">Choose  Photo/Image to Upload</label>
            <input type="file" @change="selectedfileupload" class="form-control" id="exampleFormControlFile1" />
            <i class="text-warning" v-if="formerror.image">{{ formerror.image }}</i>
            </div>
        </div>

        <div class="col-sm-6">
            <label class="form-label" for="exampleFormControlInput1">Photo Description</label>
            <textarea rows="5" type="email" v-model="forminput.caption" class="form-control" id="exampleFormControlInput1" placeholder="Description"></textarea>
            <i class="text-warning" v-if="formerror.caption">{{ formerror.caption }}</i>
        </div>

   
       
        

        <div class="col-sm-12 mt-4">
            <button type="button" class="btn btn-indigo btn-block" style="width:100%" @click.prevent="savegallerydata">Submit Photo</button>
        </div>
        </div>

        <div class="progress" v-if="loading == 'savemedia'">
            <div class="progress-bar progress-bar-striped bg-indigo " :style="{ 'width': getuploadwidth}">{{ getuploadwidth }}</div>
        </div>
    </div>
</div>


<div class="card  bg-default border-primary  mb-4 hideall" v-if="gallery" id="updategallerydata">
    <div class="card-header"><h5 class="card-title">Update gallery Photo</h5></div>
    <div class="card-body">
        <div class="form-group row mb-3">
        
        <div class="col-sm-6">
            <label class="form-label" for="exampleFormControlInput1">Title Title</label>
            <input type="email" v-model="gallery.title" class="form-control" id="exampleFormControlInput1" placeholder="Enter File Title">
            <i class="text-warning" v-if="formerror.title">{{ formerror.title }}</i>

            <div class="mt-4">
            <label class="form-label" for="exampleFormControlInput1">Choose User Photo</label>
            <input type="file" @change="updatedselectedfileupload" class="form-control" id="exampleFormControlFile1" />
            <i class="text-warning" v-if="formerror.image">{{ formerror.image }}</i>
            </div>
        </div>

        <div class="col-sm-6">
            <label class="form-label" for="exampleFormControlInput1">gallery Caption</label>
            <textarea rows="5" type="email" v-model="gallery.caption" class="form-control" id="exampleFormControlInput1" placeholder="Enter File caption"></textarea>
            <i class="text-warning" v-if="formerror.caption">{{ formerror.caption }}</i>
        </div>

   
       
        

        <div class="col-sm-12 mt-4">
            <button type="button" class="btn btn-indigo btn-block" style="width:100%" @click.prevent="updategallerydata">Submit gallery</button>
        </div>
        </div>

        <div class="progress" v-if="loading == 'savemedia'">
            <div class="progress-bar progress-bar-striped bg-indigo " :style="{ 'width': getuploadwidth}">{{ getuploadwidth }}</div>
        </div>
    </div>
</div>

<div class="row">

<div class="gallery">
<div class="gallery-image">
<ul class="gallery-image-list">

<li v-for="photo in galleries.data" :key="photo.id"><a :href="photo.image_url" itemprop="contentUrl" data-size="752x502"><img :src="photo.image_url" itemprop="thumbnail" alt="Wedding Image 1" class="img-portrait" />

<div class="row" style="position:absolute; bottom:8px; background-color: rgba(0,0,0, 0.5);">
    <div class="col-md-4" style="width:100px">
        <button v-if="photo.status == 1" @click.prevent="changegalleriestatusact(photo.id)" class="btn btn-link text-danger btn-block btn-sm" style="width:100%">Hide</button>
        <button v-else  @click.prevent="changegalleriestatusact(photo.id)" class="btn  btn-link btn-block btn-sm" style="width:100%">Reveal</button>
    </div>

    <div class="col-md-4" style="width:100px" @click.prevent="getsinglegallery(photo.id)"><button class="btn btn-link btn-block btn-sm" style="width:100%">Update</button></div>
    <div class="col-md-4" style="width:100px"><button class="btn btn-link btn-block btn-sm" @click.prevent="deletegalleryact(photo.id)" style="width:100%">Delete</button></div>
</div></a>



</li>

</ul>
</div>
</div>



</div>


</template>
<script>
import {mapGetters, mapActions } from 'vuex'
export default{

    data(){
        return{
            forminput:{
                title:'',
                image:'',
                caption:'',
  
            },

            formerror:{
                title:'',
                image:'',
                caption:'',
            }
        }
    },


    computed:{
        ...mapGetters(['loading','galleries','gallery','uploadPercentage']),

        getuploadwidth(){
            return this.uploadPercentage + "%";
        },
    },


    methods:{
        ...mapActions(['savegallery','updategallery','updategalleryimage','getallgalleries','getgallery','changegalleriestatus','deletegallery']),

        selectedfileupload(e){
            this.forminput.image = e.target.files[0];
        },


        savegallerydata(){
            if(this.forminput.title == ""){
                this.formerror.title = 'Please enter gallery title';
                setTimeout(() => this.formerror.title = '', 3000)
            }else if(this.forminput.caption == ""){
                this.formerror.caption = 'Please enter gallery caption';
                setTimeout(() => this.formerror.caption = '', 3000)
            }else if(this.forminput.image == ""){
                this.formerror.image = 'Please gallery User Image';
                setTimeout(() => this.formerror.image = '', 3000)
            }else{

                let data = new FormData();
                data.append('title',this.forminput.title),
                data.append('caption',this.forminput.caption),
                data.append('image',this.forminput.image),
                this.savegallery(data).then(()=>{

                })

            }
        },



        getsinglegallery(id){
            this.getgallery( id).then(()=>{
                $('#newgallerydata').addClass('hideall');
                $('#updategallerydata').removeClass('hideall');
            }); 
        },


        deletegalleryact(id){
            this.deletegallery(id).then(()=>{
                
            }); 
        },

        changegalleriestatusact(id){
            this.changegalleriestatus(id).then(()=>{
                
            }); 
        },


        updategallerydata(){
            let id = this.gallery.id
            if(this.gallery.title == ""){
                this.formerror.title = 'Please enter gallery title';
                setTimeout(() => this.formerror.title = '', 3000)
            }else if(this.gallery.caption == ""){
                this.formerror.caption = 'Please enter gallery caption';
                setTimeout(() => this.formerror.caption = '', 3000)
            }else if(this.gallery.link_url == ""){
                this.formerror.link_url = 'Please gallery Link';
                setTimeout(() => this.formerror.link_url = '', 3000)
            }else if(this.gallery.btn_text == ""){
                this.formerror.btn_text = 'Please  gallery Button Text';
                setTimeout(() => this.formerror.btn_text = '', 3000)
            }else{

                let data = {
                    title:this.gallery.title,
                    caption: this.gallery.caption,
                    link_url:this.gallery.link_url,
                    btn_text:this.gallery.btn_text,
                }
                this.updategallery({data, id}).then(()=>{
                    $('#newgallerydata').removeClass('hideall');
                    $('#updategallerydata').addClass('hideall');
                })

            }
        },


        updatedselectedfileupload(e){
            let id = this.gallery.id
            this.forminput.image = e.target.files[0];
            let data = new FormData();
            data.append('image', this.forminput.image)
            this.updategalleryimage({data, id}).then(()=>{
                
            }); 
        },




    },


    created(){
        this.getallgalleries();
    }


}


</script>