// import router from '@/router'
import store from '@/store'
  const adminguard = (to, from, next) => {
    if(localStorage.getItem('stiweb_api_auth_key') != null){
      if(store.getters.authuser != null ){
        if(store.getters.authuser.role == 'admin'){
          return next()
        }else{
          return next({ name: 'login' })
        }
        }
      else{
        return next({ name: 'login' })
      }
    }
    else{
      return next({ name: 'login' })
    }
  };

let routes = [
    { 
        path: "/",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/dash.vue"),
        title: 'Improving the Quality of Science Education  through Africa',
          children: [
            {
              path: "/",
              name: "welcome",
              props:true,
              component: () => import("@/views/app/mainapp/welcome.vue"),
              meta: {
              title: 'Improving the Quality of Science Education  through Africa'
              }
            },



            ///blogs

            {
              path: "/blogs",
              name: "allblogs",
              props:true,
              component: () => import("@/views/app/mainapp/blogs/blogs.vue"),
              meta: {
              title: 'Add New, Update and Delete Blogs'
              }
            },

            {
              path: "blogs/create",
              name: "addblog",
              props:true,
              component: () => import("@/views/app/mainapp/blogs/addblog.vue"),
              meta: {
              title: 'Add New, Update and Delete Blogs'
              }
            },

            {
              path: "blogs/update/:id",
              name: "updateblog",
              props:true,
              component: () => import("@/views/app/mainapp/blogs/updateblog.vue"),
              meta: {
              title: 'Add New, Update and Delete Blogs'
              }
            },


            ///whatwedo

            {
              path: "/whatwedos",
              name: "allwhatwedos",
              props:true,
              component: () => import("@/views/app/mainapp/whatwedo/whatwedos.vue"),
              meta: {
              title: 'Add New, Update and Delete whatwedos'
              }
            },

            {
              path: "whatwedos/create",
              name: "addwhatwedo",
              props:true,
              component: () => import("@/views/app/mainapp/whatwedo/addwhatwedo.vue"),
              meta: {
              title: 'Add New, Update and Delete whatwedos'
              }
            },

            {
              path: "whatwedos/update/:id",
              name: "updatewhatwedo",
              props:true,
              component: () => import("@/views/app/mainapp/whatwedo/updatewhatwedo.vue"),
              meta: {
              title: 'Add New, Update and Delete whatwedos'
              }
            },




            ///events

            {
              path: "/events",
              name: "allevents",
              props:true,
              component: () => import("@/views/app/mainapp/events/events.vue"),
              meta: {
              title: 'Add New, Update and Delete events'
              }
            },

            {
              path: "events/create",
              name: "addevent",
              props:true,
              component: () => import("@/views/app/mainapp/events/addevent.vue"),
              meta: {
              title: 'Add New, Update and Delete events'
              }
            },

            {
              path: "events/update/:id",
              name: "updateevent",
              props:true,
              component: () => import("@/views/app/mainapp/events/updateevent.vue"),
              meta: {
              title: 'Add New, Update and Delete events'
              }
            },




            ///careers

            {
              path: "/careers",
              name: "allcareers",
              props:true,
              component: () => import("@/views/app/mainapp/careers/careers.vue"),
              meta: {
              title: 'Add New, Update and Delete careers'
              }
            },

            {
              path: "careers/create",
              name: "addcareer",
              props:true,
              component: () => import("@/views/app/mainapp/careers/addcareer.vue"),
              meta: {
              title: 'Add New, Update and Delete careers'
              }
            },

            {
              path: "careers/update/:id",
              name: "updatecareer",
              props:true,
              component: () => import("@/views/app/mainapp/careers/updatecareer.vue"),
              meta: {
              title: 'Add New, Update and Delete careers'
              }
            },


            //webmananer

            {
              path: "/webmanger",
              props:true,
              component: () => import("@/views/app/mainapp/webmanager/main.vue"),
              children:[


                {
                  path: "/banners",
                  name: "banners",
                  props:true,
                  component: () => import("@/views/app/mainapp/webmanager/banners.vue"),
                  meta: {
                  title: 'Manager Website Banners'
                  }
                },

                {
                  path: "/clients",
                  name: "clients",
                  props:true,
                  component: () => import("@/views/app/mainapp/webmanager/clients.vue"),
                  meta: {
                  title: 'Manager Website Clients'
                  }
                },


                {
                  path: "/testimonies",
                  name: "testimonies",
                  props:true,
                  component: () => import("@/views/app/mainapp/webmanager/testimonies.vue"),
                  meta: {
                  title: 'Manager Website Testimonies'
                  }
                },


                {
                  path: "/team",
                  name: "team",
                  props:true,
                  component: () => import("@/views/app/mainapp/webmanager/team.vue"),
                  meta: {
                  title: 'Manager Website Team'
                  }
                },

                {
                  path: "/gallery",
                  name: "gallery",
                  props:true,
                  component: () => import("@/views/app/mainapp/webmanager/gallery.vue"),
                  meta: {
                  title: 'Manager Website Gallery'
                  }
                },


                {
                  path: "/webmain",
                  name: "webmain",
                  props:true,
                  component: () => import("@/views/app/mainapp/webmanager/webmain.vue"),
                  meta: {
                  title: 'Manager Website Settings'
                  }
                },


              ]
            },




            //filemanager

            {
              path: "/file-manager",
              name: "filemanager",
              props:true,
              component: () => import("@/views/app/mainapp/filemanager/filemanager.vue"),
              meta: {
              title: 'Store, Share and Keep Files'
              }
            },


          ]
        },

    ]

export default routes;