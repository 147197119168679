<template>
<div class="row">

<div class="col-xl-9 mb-3">

<h1 class="page-header">
Add What We Do From Here 
</h1>
<hr class="mb-4" />



<div class="card">
<div class="card-body pb-2">
<div class="row">
<div class="col-xl-12 p-3">
<div class="form-group mb-3">
<label class="form-label" for="exampleFormControlInput1">What We Do Title</label>
<input type="text" v-model="forminput.title" class="form-control" id="exampleFormControlInput1" placeholder="What We Do Title" />
<i class="text-danger" v-if="formerror.title">{{ formerror.title }}</i>
</div>

<div class="row">
<div class="col-md-8">
<div class="form-group mb-3">
<label class="form-label" for="exampleFormControlInput1">What We Do Caption</label>
<input type="text" v-model="forminput.caption" class="form-control" id="exampleFormControlInput1" placeholder="What We Do Caption" />
<i class="text-danger" v-if="formerror.caption">{{ formerror.caption }}</i>
</div>

<div class="form-group mb-3">
<label class="form-label" for="exampleFormControlInput1">What We Do Keywords</label>
<input type="text" v-model="forminput.meta" class="form-control" id="exampleFormControlInput1" placeholder="What We Do Meta" />
<i class="text-danger" v-if="formerror.meta">{{ formerror.meta }}</i>
</div>
</div>

<div class="col-md-4 text-center">
    <label class="form-label text-center mb-2" for="exampleFormControlInput1">Add Image</label>
<div class="wrapper text-center">
  <button class="no-image" id="img-result">Upload Image</button>
   <input for="img-result" @change="whatwedoimageupload" type="file" class="hideal"/>
  <img id="image-preview" src="" />
</div>
<i class="text-danger" v-if="formerror.image">{{ formerror.image }}</i>
</div>

</div>



<div class="form-group mb-3">
<label class="form-label" for="exampleFormControlTextarea1">What We Do Body</label>

 <editor v-model="forminput.body"
    api-key="y2537ncsedjdv72e6n9m0lr7lw07recoqjscjoi098syu085"
    :init="{
      menubar: false,
      plugins: 'a11ychecker advcode casechange export formatpainter image editimage linkchecker autolink lists checklist media mediaembed pageembed permanentpen powerpaste table advtable tableofcontents  tinymcespellchecker',
      toolbar: 'styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | link image emoticons a11ycheck addcomment showcomments casechange checklist code export formatpainter image editimage pageembed permanentpen table tableofcontents'}"
  />

<i class="text-danger" v-if="formerror.body">{{ formerror.body }}</i>
</div>
</div>
</div>

<button type="submit" class="btn btn-primary btn-lg d-block w-100 fw-500 mb-3" @click.prevent="addwhatwedodata">Save What We Do</button>
</div>
</div>
</div>


<div class="col-xl-3">
    <nav id="sidebar-bootstrap" class="navbar navbar-sticky d-none d-xl-block">
<nav class="nav p-4">
<router-link :to="{name:'allwhatwedos'}" class="menu-link btn btn-primary btn-sm mt-4">What We Do List</router-link>

<!-- <a class="nav-link" href="#formControls" data-toggle="scroll-to">Add Blog</a> -->
</nav>
</nav>


</div>
</div>
</template>

<script>
import {mapGetters , mapActions } from 'vuex'
import Editor from '@tinymce/tinymce-vue'
export default {

components: {
'editor': Editor
},

data(){
    return{
        forminput:{
            title:'',
            meta:"",
            caption:"",
            body:"",
            image:""
        },

        formerror:{
            title:'',
            meta:"",
            caption:"",
            body:"",
            image:""
        }
    }
},


computed:{
    ...mapGetters(['msg', 'apierror','error','loading'])
},


methods:{
 ...mapActions(['savewhatwedo']),

whatwedoimageupload(e){
    this.forminput.image = e.target.files[0];
    // this.imageinfo = e.target.files[0].name.substring(0,50);
},


 addwhatwedodata(){
    if(this.forminput.title == ""){
        this.formerror.title = 'Please enter What We Do title';
        setTimeout(() => this.formerror.title = '', 3000)
    }else if(this.forminput.meta == ""){
        this.formerror.meta = 'Please Enter What We Do meta';
        setTimeout(() => this.formerror.meta = '', 3000)
    }else if(this.forminput.caption == ""){
        this.formerror.caption = 'Please enter What We Do caption';
        setTimeout(() => this.formerror.caption = '', 3000)
    }else if(this.forminput.image == ""){
        this.formerror.image = 'Please enter What We Do Image';
        setTimeout(() => this.formerror.image = '', 3000)
    }else if(this.forminput.body == ""){
        this.formerror.body = 'Please  body';
        setTimeout(() => this.formerror.body = '', 3000)
    }else{

        let data = new FormData();
        data.append('title', this.forminput.title)
        data.append('caption', this.forminput.caption)
        data.append('meta', this.forminput.meta)
        data.append('body', this.forminput.body)
        data.append('image', this.forminput.image)

        this.savewhatwedo(data).then(()=>{

        })

    }
 }
}

    
}
</script>

<style>
.wrapper {
margin-left:32% !important
}
.wrapper:active #img-result {
  margin-top: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
.wrapper #img-result {
  cursor: pointer;
  margin: 0;
  position: relative;
  background:blue;
  background-size: cover;
  background-position: center;
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  color: rgba(0, 0, 0, 0);
  transition: box-shadow 0.3s, margin 0.3s, background-image 1.5s;
}
.wrapper #img-result.no-image:before {
  font-family: 'FontAwesome';
  content: "\f030";
  position: absolute;
  left: 50%;
  top: 50%;
  color: #fff;
  font-size: 48px;
  opacity: 0.8;
  transform: translate(-50%, -50%);
  text-shadow: 0 0px 5px rgba(0, 0, 0, 0.4);
}
.wrapper button {
  margin-top: 20px;
  display: block;
  font-family: 'Open Sans Condensed', sans-serif;
  background: #1abc9c;
  width: 100%;
  border: none;
  color: #fff;
  padding: 10px;
  letter-spacing: 1.3px;
  font-size: 1.05em;
  border-radius: 5px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.3);
  outline: 0;
  transition: box-shadow 0.3s, margin-top 0.3s, padding 0.3s;
}
.wrapper button:active {
  box-shadow: none;
  margin-top: 24px;
  padding: 8px;
}
.show-button {
  background: #264974;
  border: none;
  color: #fff;
  padding: 10px 20px;
  float: right;
  display: none;
}
.upload-result {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow-y: auto;
}
.upload-result__content {
  word-break: break-all;
  font-family: 'Source Code Pro';
  overflow-wrap: break-word;
}

</style>