<template>
<div class="row">
    <div class="col-xl-10 mb-3">
        <router-view/>
    </div>

    <div class="col-xl-2">
        <nav id="sidebar-bootstrap" class="navbar navbar-sticky d-none d-xl-block">
            <nav class="nav">
            <router-link :to="{name:'banners'}" v-show="currentRouteName != 'banners'" class="menu-link btn btn-primary btn-sm mb-2">Banners</router-link>
            <router-link :to="{name:'clients'}" v-show="currentRouteName != 'clients'" class="menu-link btn btn-primary btn-sm mb-2">Clients</router-link>
            <router-link :to="{name:'testimonies'}" v-show="currentRouteName != 'testimonies'" class="menu-link btn btn-primary btn-sm mb-2">Testimonies</router-link>
            <router-link :to="{name:'team'}" v-show="currentRouteName != 'team'" class="menu-link btn btn-primary btn-sm mb-2">Team</router-link>
            <router-link :to="{name:'gallery'}" v-show="currentRouteName != 'gallery'" class="menu-link btn btn-primary btn-sm mb-2">Gallery</router-link>
            
            </nav>
        </nav>
    </div>
</div>

</template>
<script>

export default{
    

    computed:{


    currentRouteName() {
        return this.$route.name;
    }
    }
}

</script>