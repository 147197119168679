<template>
<div class="row">

<div class="col-xl-9 mb-3">

<h1 class="page-header">
Udpdate Job Details From Here 
</h1>
<hr class="mb-4" />



<div class="card" v-if="career">
<div class="card-body pb-2">
<div class="row">
<div class="col-xl-12 p-3">
<div class="form-group mb-3">
<label class="form-label" for="exampleFormControlInput1">career Title</label>
<input type="text" v-model="career.title" class="form-control" id="exampleFormControlInput1" placeholder="career Title" />
<i class="text-danger" v-if="formerror.title">{{ formerror.title }}</i>
</div>

<div class="row">
<div class="col-md-8">
<div class="form-group mb-3">
<label class="form-label" for="exampleFormControlInput1">career Caption</label>
<input type="text" v-model="career.caption" class="form-control" id="exampleFormControlInput1" placeholder="career Caption" />
<i class="text-danger" v-if="formerror.caption">{{ formerror.caption }}</i>
</div>

<div class="form-group mb-3">
<label class="form-label" for="exampleFormControlInput1">career Keywords</label>
<input type="text" v-model="career.meta" class="form-control" id="exampleFormControlInput1" placeholder="career Meta" />
<i class="text-danger" v-if="formerror.meta">{{ formerror.meta }}</i>
</div>
</div>

<div class="col-md-4">

<div class="form-group mb-3">
<label class="form-label" for="exampleFormControlInput1">Choose Job Image</label>
<input type="file" @change="careerimageupload" class="form-control" id="exampleFormControlFile1" />
<i class="text-warning" v-if="formerror.image">{{ formerror.image }}</i>
</div>    


<div class="form-group mb-3">
<label class="form-label" for="exampleFormControlInput1">Choose Job Document</label>
<input type="file" @change="careerfileupload" class="form-control" id="exampleFormControlFile1" />
<i class="text-warning" v-if="formerror.file">{{ formerror.file }}</i>
</div>  
  
</div>

</div>



<div class="form-group mb-3">
<label class="form-label" for="exampleFormControlTextarea1">Job Description</label>

 <editor v-model="career.body"
    api-key="y2537ncsedjdv72e6n9m0lr7lw07recoqjscjoi098syu085"
    :init="{
      menubar: false,
      plugins: 'a11ychecker advcode casechange export formatpainter image editimage linkchecker autolink lists checklist media mediaembed pageembed permanentpen powerpaste table advtable tableofcontents  tinymcespellchecker',
      toolbar: 'styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | link image emoticons a11ycheck addcomment showcomments casechange checklist code export formatpainter image editimage pageembed permanentpen table tableofcontents'}"
  />

<i class="text-danger" v-if="formerror.careerbody">{{ formerror.body }}</i>
</div>
</div>

</div>
<button v-if="loading == career.id" type="submit" class="btn btn-primary btn-lg d-block w-100 fw-500 mb-3" @click.prevent="updatecareerdata(career.id)">
<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  Loading...
</button>
<button v-else type="submit" class="btn btn-primary btn-lg d-block w-100 fw-500 mb-3" @click.prevent="updatecareerdata(career.id)">Save Career</button>
</div>
</div>
</div>


<div class="col-xl-3">
    <nav id="sidebar-bootstrap" class="navbar navbar-sticky d-none d-xl-block">
<nav class="nav p-4">
<router-link :to="{name:'addcareer'}" class="menu-link btn btn-primary btn-sm">Add New Career</router-link>
<router-link :to="{name:'allcareers'}" class="menu-link btn btn-primary btn-sm mt-4">Career List</router-link>

<!-- <a class="nav-link" href="#formControls" data-toggle="scroll-to">Add career</a> -->
</nav>
</nav>


</div>
</div>
</template>

<script>
import {mapGetters , mapActions } from 'vuex'
import Editor from '@tinymce/tinymce-vue'
export default {

components: {
'editor': Editor
},

data(){
    return{
        forminput:{
            title:'',
            meta:"",
            caption:"",
            body:"",
            image:"",
            file:""
        },

        formerror:{
            title:'',
            meta:"",
            caption:"",
            body:"",
            image:"",
            file:""
        }
    }
},


computed:{
    ...mapGetters(['msg', 'apierror','error','loading', 'career'])
},


methods:{
 ...mapActions(['savecareer','getcareer','updatecareerimage','updatecareerfile','updatecareer']),

careerimageupload(e){
    let id = this.$route.params.id
    this.forminput.image = e.target.files[0];
    let data = new FormData();
    data.append('image', this.forminput.image)
    this.updatecareerimage({data, id}).then(()=>{
        
    }); 
},


careerfileupload(e){
    let id = this.$route.params.id
    this.forminput.file = e.target.files[0];
    let data = new FormData();
    data.append('file', this.forminput.file)
    this.updatecareerfile({data, id}).then(()=>{
        
    }); 
},


 updatecareerdata(){
     let id = this.$route.params.id
    if(this.career.title == ""){
        this.formerror.title = 'Please enter career title';
        setTimeout(() => this.formerror.title = '', 3000)
    }else if(this.career.meta == ""){
        this.formerror.meta = 'Please Enter career meta';
        setTimeout(() => this.formerror.meta = '', 3000)
    }else if(this.career.caption == ""){
        this.formerror.caption = 'Please enter career caption';
        setTimeout(() => this.formerror.caption = '', 3000)
    }else if(this.career.body == ""){
        this.formerror.body = 'Please  body';
        setTimeout(() => this.formerror.body = '', 3000)
    }else{

        let data = {
            title:this.career.title,
            caption: this.career.caption,
            meta:this.career.meta,
            body:this.career.body,
        }
        this.updatecareer({data, id}).then(()=>{

        })

    }
 }
},

created(){
    let id = this.$route.params.id
    this.getcareer(id)
}


    
}
</script>

<style>
.wrapper {
margin-left:32% !important
}
.wrapper:active #img-result {
  margin-top: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
.wrapper #img-result {
  cursor: pointer;
  margin: 0;
  position: relative;
  background:blue;
  background-size: cover;
  background-position: center;
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  color: rgba(0, 0, 0, 0);
  transition: box-shadow 0.3s, margin 0.3s, background-image 1.5s;
}
.wrapper #img-result.no-image:before {
  font-family: 'FontAwesome';
  content: "\f030";
  position: absolute;
  left: 50%;
  top: 50%;
  color: #fff;
  font-size: 48px;
  opacity: 0.8;
  transform: translate(-50%, -50%);
  text-shadow: 0 0px 5px rgba(0, 0, 0, 0.4);
}
.wrapper button {
  margin-top: 20px;
  display: block;
  font-family: 'Open Sans Condensed', sans-serif;
  background: #1abc9c;
  width: 100%;
  border: none;
  color: #fff;
  padding: 10px;
  letter-spacing: 1.3px;
  font-size: 1.05em;
  border-radius: 5px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.3);
  outline: 0;
  transition: box-shadow 0.3s, margin-top 0.3s, padding 0.3s;
}
.wrapper button:active {
  box-shadow: none;
  margin-top: 24px;
  padding: 8px;
}
.show-button {
  background: #264974;
  border: none;
  color: #fff;
  padding: 10px 20px;
  float: right;
  display: none;
}
.upload-result {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow-y: auto;
}
.upload-result__content {
  word-break: break-all;
  font-family: 'Source Code Pro';
  overflow-wrap: break-word;
}

</style>