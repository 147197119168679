<template>
<h1 class="page-header">
Manage Webiste Banners From Here 
</h1>
<hr class="mb-4" />

<div class="card  bg-default border-primary  mb-4" id="newbannerdata">
    <div class="card-header"><h5 class="card-title">Add New Banner to Database</h5></div>
    <div class="card-body">
        <div class="form-group row mb-3">
        
        <div class="col-sm-6">
            <label class="form-label" for="exampleFormControlInput1">Title Title</label>
            <input type="email" v-model="forminput.title" class="form-control" id="exampleFormControlInput1" placeholder="Enter File Title">
            <i class="text-warning" v-if="formerror.title">{{ formerror.title }}</i>
        </div>

        <div class="col-sm-6">
            <label class="form-label" for="exampleFormControlInput1">Banner Caption</label>
            <input type="email" v-model="forminput.caption" class="form-control" id="exampleFormControlInput1" placeholder="Enter File caption">
            <i class="text-warning" v-if="formerror.caption">{{ formerror.caption }}</i>
        </div>

   
       
        <div class="col-sm-3">
            <label class="form-label" for="exampleFormControlInput1">Choose File</label>
            <input type="file" @change="selectedfileupload" class="form-control" id="exampleFormControlFile1" />
            <i class="text-warning" v-if="formerror.image">{{ formerror.image }}</i>
        </div>

        <div class="col-sm-5">
            <label class="form-label" for="exampleFormControlInput1">Banner Url</label>
            <input type="email" v-model="forminput.link_url" class="form-control" id="exampleFormControlInput1" placeholder="Enter File link_url">
            <i class="text-warning" v-if="formerror.link_url">{{ formerror.link_url }}</i>
        </div>
        <div class="col-sm-4">
            <label class="form-label" for="exampleFormControlInput1">Banner Btn Text</label>
            <input type="email" v-model="forminput.btn_text" class="form-control" id="exampleFormControlInput1" placeholder="Enter File btn_text">
            <i class="text-warning" v-if="formerror.btn_text">{{ formerror.btn_text }}</i>
        </div>

        <div class="col-sm-12 mt-4">
            <button type="button" class="btn btn-indigo btn-block" @click.prevent="savebannerdata">Submit File</button>
        </div>
        </div>

        <div class="progress" v-if="loading == 'savemedia'">
            <div class="progress-bar progress-bar-striped bg-indigo " :style="{ 'width': getuploadwidth}">{{ getuploadwidth }}</div>
        </div>
    </div>
</div>


<div class="card  bg-default border-primary  mb-4 hideall" v-if="banner" id="updatebannerdata">
    <div class="card-header"><h5 class="card-title">Add New Banner to Database</h5></div>
    <div class="card-body">
        <div class="form-group row mb-3">
        
        <div class="col-sm-6">
            <label class="form-label" for="exampleFormControlInput1">Title Title</label>
            <input type="email" v-model="banner.title" class="form-control" id="exampleFormControlInput1" placeholder="Enter File Title">
            <i class="text-warning" v-if="formerror.title">{{ formerror.title }}</i>
        </div>

        <div class="col-sm-6">
            <label class="form-label" for="exampleFormControlInput1">Banner Caption</label>
            <input type="email" v-model="banner.caption" class="form-control" id="exampleFormControlInput1" placeholder="Enter File caption">
            <i class="text-warning" v-if="formerror.caption">{{ formerror.caption }}</i>
        </div>

   
       
        <div class="col-sm-3">
            <label class="form-label" for="exampleFormControlInput1">Choose File</label>
            <input type="file" @change="updatedselectedfileupload" class="form-control" id="exampleFormControlFile1" />
            <i class="text-warning" v-if="formerror.image">{{ formerror.image }}</i>
        </div>

        <div class="col-sm-5">
            <label class="form-label" for="exampleFormControlInput1">Banner Url</label>
            <input type="email" v-model="banner.link_url" class="form-control" id="exampleFormControlInput1" placeholder="Enter File link_url">
            <i class="text-warning" v-if="formerror.link_url">{{ formerror.link_url }}</i>
        </div>
        <div class="col-sm-4">
            <label class="form-label" for="exampleFormControlInput1">Banner Btn Text</label>
            <input type="email" v-model="banner.btn_text" class="form-control" id="exampleFormControlInput1" placeholder="Enter File btn_text">
            <i class="text-warning" v-if="formerror.btn_text">{{ formerror.btn_text }}</i>
        </div>

        <div class="col-sm-12 mt-4">
            <button type="button" class="btn btn-indigo btn-block" @click.prevent="updatebannerdata">Submit File</button>
        </div>
        </div>

        <div class="progress" v-if="loading == 'savemedia'">
            <div class="progress-bar progress-bar-striped bg-indigo " :style="{ 'width': getuploadwidth}">{{ getuploadwidth }}</div>
        </div>
    </div>
</div>

<div class="row">

    <div class="col-xl-6 mb-3" v-for="bann in banners.data" :key="bann.id">
        <div class="card">
            <div class="row gx-0">
                <div class="col-md-4">
                <img :src="bann.image_url" alt="" style="height:200px; width:100%; object-size:cover; object-fit:center" class="card-img rounded-0" />
                </div>
                <div class="col-md-8">
                <div class="card-body">
                    <h5 class="card-title">{{ bann.title }}</h5>
                    <p class="card-text">{{ bann.caption}}</p>
                    <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>

                    <div class="row" style="position:absolute; bottom:8px">
                        <div class="col-md-4" style="width:100px">
                            <button v-if="bann.status == 1" @click.prevent="changebannerstatusact(bann.id)" class="btn btn-pink btn-block btn-sm" style="width:100%">Hide</button>
                            <button v-else  @click.prevent="changebannerstatusact(bann.id)" class="btn btn-success btn-block btn-sm" style="width:100%">Reveal</button>
                        </div>

                        <div class="col-md-4" style="width:100px" @click.prevent="getsinglebanner(bann.id)"><button class="btn btn-indigo btn-block btn-sm" style="width:100%">Update</button></div>
                        <div class="col-md-4" style="width:100px"><button class="btn btn-danger btn-block btn-sm" @click.prevent="deletebanneract(bann.id)" style="width:100%">Delete</button></div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>



</div>


</template>
<script>
import {mapGetters, mapActions } from 'vuex'
export default{

    data(){
        return{
            forminput:{
                title:'',
                image:'',
                caption:'',
                link_url:'',
                btn_text:''
            },

            formerror:{
                title:'',
                image:'',
                caption:'',
                link_url:'',
                btn_text:''
            }
        }
    },


    computed:{
        ...mapGetters(['loading','banners','banner','uploadPercentage']),

        getuploadwidth(){
            return this.uploadPercentage + "%";
        },
    },


    methods:{
        ...mapActions(['savebanner','updatebanner','updatebannerimage','getallbanners','getbanner','changebannerstatus','deletebanner']),

        selectedfileupload(e){
            this.forminput.image = e.target.files[0];
        },


        savebannerdata(){
            if(this.forminput.title == ""){
                this.formerror.title = 'Please enter Banner title';
                setTimeout(() => this.formerror.title = '', 3000)
            }else if(this.forminput.caption == ""){
                this.formerror.caption = 'Please enter Banner caption';
                setTimeout(() => this.formerror.caption = '', 3000)
            }else if(this.forminput.image == ""){
                this.formerror.image = 'Please Banner Link';
                setTimeout(() => this.formerror.image = '', 3000)
            }else if(this.forminput.link_url == ""){
                this.formerror.link_url = 'Please Banner Link';
                setTimeout(() => this.formerror.link_url = '', 3000)
            }else if(this.forminput.btn_text == ""){
                this.formerror.btn_text = 'Please  Banner Button Text';
                setTimeout(() => this.formerror.btn_text = '', 3000)
            }else{

                let data = new FormData();
                data.append('title',this.forminput.title),
                data.append('caption',this.forminput.caption),
                data.append('link_url',this.forminput.link_url),
                data.append('btn_text',this.forminput.btn_text),
                data.append('image',this.forminput.image),
                this.savebanner(data).then(()=>{

                })

            }
        },



        getsinglebanner(id){
            this.getbanner( id).then(()=>{
                $('#newbannerdata').addClass('hideall');
                $('#updatebannerdata').removeClass('hideall');
            }); 
        },


        deletebanneract(id){
            this.deletebanner(id).then(()=>{
                
            }); 
        },

        changebannerstatusact(id){
            this.changebannerstatus(id).then(()=>{
                
            }); 
        },


        updatebannerdata(){
            let id = this.banner.id
            if(this.banner.title == ""){
                this.formerror.title = 'Please enter Banner title';
                setTimeout(() => this.formerror.title = '', 3000)
            }else if(this.banner.caption == ""){
                this.formerror.caption = 'Please enter Banner caption';
                setTimeout(() => this.formerror.caption = '', 3000)
            }else if(this.banner.link_url == ""){
                this.formerror.link_url = 'Please Banner Link';
                setTimeout(() => this.formerror.link_url = '', 3000)
            }else if(this.banner.btn_text == ""){
                this.formerror.btn_text = 'Please  Banner Button Text';
                setTimeout(() => this.formerror.btn_text = '', 3000)
            }else{

                let data = {
                    title:this.banner.title,
                    caption: this.banner.caption,
                    link_url:this.banner.link_url,
                    btn_text:this.banner.btn_text,
                }
                this.updatebanner({data, id}).then(()=>{
                    $('#newbannerdata').removeClass('hideall');
                    $('#updatebannerdata').addClass('hideall');
                })

            }
        },


        updatedselectedfileupload(e){
            let id = this.banner.id
            this.forminput.image = e.target.files[0];
            let data = new FormData();
            data.append('image', this.forminput.image)
            this.updatebannerimage({data, id}).then(()=>{
                
            }); 
        },




    },


    created(){
        this.getallbanners();
    }


}


</script>